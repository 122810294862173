import { Card } from "@mui/material";
import Button from "components/ui/button";
import { DatePicker } from "components/ui/date-picker";
import Description from "components/ui/description";
import Input from "components/ui/forms/input";
import Label from "components/ui/label";
import Select from "components/ui/select/select";
import moment from "moment";
import { useModalAction } from "provider/modal.context";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useProductManagementMarketPlaceList } from "service/hook/business";
import {
  useServiceBookingSlotRelease,
  useServiceBookingSlotsListWithPaging,
} from "service/hook/serviceBooking";
import { userStore } from "store";
import {
  MainResponse,
  ProductManagementMarketPlaceListItem,
  ProductManagementMarketPlaceListResponse,
  ProductManagementMarketPlaceReq,
  SlotStatus,
} from "types";
import {
  ServiceBookingSlot,
  ServiceBookingSlotsPagingResponse,
} from "types/serviceBooking";
import { PageSize } from "utils/constants";
import BookingManagementList from "./BookingManagementList";
import { toast } from "react-toastify";

export default function BookingManagementFormView() {
  const { t } = useTranslation();
  const { user, businessItem } = userStore((state) => state);
  const { openModal } = useModalAction();

  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [marketPlaceList, setMarketPlaceList] = useState<
    ProductManagementMarketPlaceListItem[]
  >([]);
  const [selectedMarketPlace, setSelectedMarketPlace] = useState<
    ProductManagementMarketPlaceListItem | undefined
  >(undefined);
  const [selectedStatus, setSelectedStatus] = useState<any>({
    id: "",
    name: "all",
  });
  const [customerEmail, setCustomerEmail] = useState<string>("");
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [serviceBookingSlots, setServiceBookingSlots] = useState<
    ServiceBookingSlot[]
  >([]);

  const slotStatuses = [
    { id: "", name: "all" },
    { id: SlotStatus.Available, name: SlotStatus.Available },
    { id: SlotStatus.Booked, name: SlotStatus.Booked },
    { id: SlotStatus.Cancelled, name: SlotStatus.Cancelled },
    { id: SlotStatus.Closed, name: SlotStatus.Closed },
    { id: SlotStatus.Done, name: SlotStatus.Done },
  ];
  const { mutate: mutateMarketPlaceList, isLoading: loadingMarketPlaceList } =
    useProductManagementMarketPlaceList();

  const {
    mutate: mutateServiceBookingSlotRelease,
    isLoading: loadingServiceBookingSlotRelease,
  } = useServiceBookingSlotRelease();

  const {
    mutate: mutateServiceBookingSlotsListWithPaging,
    isLoading: loadingServiceBookingSlotsListWithPaging,
  } = useServiceBookingSlotsListWithPaging();

  const getMarketPlaceList = (id?: string) => {
    const input: ProductManagementMarketPlaceReq = {};

    if (id) {
      input.marketplace_id = id;
    }

    mutateMarketPlaceList(input, {
      onSuccess: (successData: ProductManagementMarketPlaceListResponse) => {
        if (successData.final_result_code === "000") {
          const newMarketPlaces = successData.captured_data?.filter(
            (item) => item.marketplace_id === "GBR"
          );
          setMarketPlaceList(newMarketPlaces ?? []);
        }
      },
    });
  };

  const getServiceBookingSlotsList = (pageNumber?: number) => {
    mutateServiceBookingSlotsListWithPaging(
      {
        businessdetail_id: `${businessItem?.business_id ?? 0}`,
        booking_status: selectedStatus.id,
        marketplaceId: selectedMarketPlace?.marketplace_id ?? "GBR",
        customer_email: customerEmail,
        start_date: startDate
          ? moment(startDate).format("DD-MM-YYYY")
          : undefined,
        end_date: endDate ? moment(endDate).format("DD-MM-YYYY") : undefined,
        page: pageNumber ?? page,
        size: PageSize,
      },
      {
        onSuccess: (successData: ServiceBookingSlotsPagingResponse) => {
          if (successData.final_result_code === "000") {
            setServiceBookingSlots(successData.captured_data);
            setTotalCount(successData.total_count ?? 0);
          }
        },
      }
    );
  };

  const deleteItem = (item: any) => {
    openModal("SERVICE_BOOKING_CONDITION_DELETE", {
      item: { ...item },
      callback: () => {
        getServiceBookingSlotsList(1);
      },
    });
  };

  useEffect(() => {
    getMarketPlaceList();
  }, []);

  useEffect(() => {
    if (businessItem) getServiceBookingSlotsList();
  }, [businessItem, page]);

  const handleChangePage = (pageNumber: number) => {
    setPage(pageNumber);
  };

  const releaseSlot = (item: ServiceBookingSlot) => {
    mutateServiceBookingSlotRelease(
      {
        businessdetail_id: `${businessItem?.business_id ?? 0}`,
        marketplaceId: "GBR",
        service_booking_record_id: `${item.service_booking_record_id}`,
        email: user?.email ?? "",
      },
      {
        onSuccess: (successData: MainResponse) => {
          if (successData.final_result_code === "000") {
            try {
              toast.success(successData.general_result);
              getServiceBookingSlotsList(1);
            } catch (err) {
              console.log("err => ", err);
            }
          } else {
          }
        },
      }
    );
  };
  return (
    <div className="my-5 flex flex-wrap sm:my-8">
      <Description
        details={[
          t("form:form-description-booking-management1"),
          t("form:form-description-booking-management2"),
          t("form:form-description-booking-management3"),
          t("form:form-description-booking-management4"),
          t("form:form-description-booking-management5"),
          t("form:form-description-booking-management6"),
          t("form:form-description-booking-management7"),
          t("form:form-description-booking-management8"),
        ]}
        className="w-full px-0 pb-5 sm:w-4/12 sm:py-8 sm:pe-4 md:w-1/4 md:pe-5 "
      />
      <Card className="w-full sm:w-8/12 md:w-3/4">
        <div className="p-4 pb-0">
          <div className="flex gap-4 w-full">
            <div className="w-3/4 flex gap-4">
              <div className="w-1/2">
                <Label className="text-left">{"Booking start date"}</Label>
                <DatePicker
                  className="w-full"
                  selected={startDate}
                  onChange={(date: any) => {
                    setStartDate(date);
                  }}
                  showYearDropdown
                  showMonthDropdown
                  dateFormat="yyy-MM-dd"
                />
              </div>
              <div className="w-1/2">
                <Label className="text-left">{"Booking end date"}</Label>
                <DatePicker
                  className="w-full"
                  selected={endDate}
                  onChange={(date: any) => {
                    setEndDate(date);
                  }}
                  showYearDropdown
                  showMonthDropdown
                  dateFormat="yyy-MM-dd"
                />
              </div>
            </div>
            <div className="w-1/4">
              <div className="mb-5 w-full">
                <Label className="text-left">
                  {t("common:text-marketplace")}
                </Label>
                <Select
                  name="marketPlace"
                  value={selectedMarketPlace}
                  onChange={(value: any) => {
                    setSelectedMarketPlace(value);
                  }}
                  getOptionLabel={(option: any) => `${option.marketplace_name}`}
                  getOptionValue={(option: any) => option.marketplace_id}
                  options={marketPlaceList!}
                  isLoading={loadingMarketPlaceList}
                />
              </div>
            </div>
          </div>
          <div className="flex gap-4 w-full mb-1">
            <div className="w-3/4 flex gap-4">
              <div className="w-1/2">
                <Label className="text-left">{t("common:text-status")}</Label>
                <Select
                  name="status"
                  value={selectedStatus}
                  onChange={(value: any) => {
                    setSelectedStatus(value);
                  }}
                  getOptionLabel={(option: any) => `${option.name}`}
                  getOptionValue={(option: any) => option.id}
                  options={slotStatuses}
                />
              </div>
              <div className="w-1/2">
                <Input
                  label={t("common:text-customer-email")}
                  name="customer-email"
                  value={customerEmail}
                  onChange={(e) => {
                    setCustomerEmail(e.target.value);
                  }}
                  variant="outline"
                  className="mb-5"
                  type="email"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className="text-start px-4"
          style={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <div
            className="mb-4 text-start"
            style={{
              display: "flex",
              justifyContent: "start",
            }}
          >
            <Button
              onClick={() => {
                getServiceBookingSlotsList(1);
              }}
              loading={loadingServiceBookingSlotsListWithPaging}
              disabled={loadingServiceBookingSlotsListWithPaging}
            >
              {"Search"}
            </Button>
          </div>
        </div>

        <BookingManagementList
          serviceBookingSlotsList={serviceBookingSlots}
          paginatorInfo={{
            total: totalCount,
            currentPage: page,
            pageSize: PageSize,
          }}
          onPagination={handleChangePage}
          onDeleteItem={(item: ServiceBookingSlot) => {
            deleteItem(item);
          }}
          onReleaseItem={(item: ServiceBookingSlot) => {
            releaseSlot(item);
          }}
        />
      </Card>
    </div>
  );
}
