import { Rating } from "@mui/material";
import Button from "components/ui/button";
import Card from "components/ui/cards/card";
import Label from "components/ui/label";
import Select from "components/ui/select/select";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useBusinessReviewsList,
  useSellerOverallScore,
} from "service/hook/business";
import { userStore } from "store";
import {
  BusinessReview,
  BusinessReviewsRequest,
  BusinessReviewsResponse,
  SellerOverallScore,
  SellerOverallScoreResponse,
} from "types/businessReview";
import { PageSize } from "utils/constants";
import BusinessReviewsList from "./BusinessReviewsList";

export default function BusinessReviewsFormView() {
  const { t } = useTranslation();
  const { businessItem } = userStore((state) => state);
  const [scoreData, setRateData] = useState<SellerOverallScore>();

  const [customerEmail, setCustomerEmail] = useState<string>("");
  const [sellerEmail, setSellerEmail] = useState<string>("");
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [businessReviewsList, setBusinessReviewsList] = useState<
    BusinessReview[]
  >([]);

  const { mutate: mutateBusinessReviews, isLoading } = useBusinessReviewsList();
  const { mutate: mutateSellerOverallScore } = useSellerOverallScore();
  const [reviewStatus, setReviewStatus] = useState<any>({
    id: 1,
    name: "All",
  });

  const reviewStatuesList = [
    {
      id: 1,
      name: "All",
    },
    {
      id: 2,
      name: "New",
    },
    {
      id: 3,
      name: "Approved",
    },
    {
      id: 4,
      name: "Rejected",
    },
  ];

  const getBusinessReviews = () => {
    const input: BusinessReviewsRequest = {
      page: page,
      size: PageSize,
      marketplaceId: "GBR",
      businessdetail_id: `${businessItem?.business_id}`,
      review_status: reviewStatus?.name,
    };

    mutateBusinessReviews(input, {
      onSuccess: (successData: BusinessReviewsResponse) => {
        if (successData.final_result_code === "000") {
          setBusinessReviewsList(successData.captured_data);
          setTotalCount(successData.total_count ?? 0);
        }
      },
    });
  };

  useEffect(() => {
    if (businessItem) {
      getBusinessReviews();
      getSellerOverallScore();
    }
  }, [businessItem, page]);

  const onPagination = (pageNumber: number) => {
    setPage(pageNumber);
  };

  const getSellerOverallScore = () => {
    mutateSellerOverallScore(
      {
        businessdetail_id: `${businessItem?.business_id}`,
        marketplaceId: "GBR",
      },
      {
        onSuccess: (successData: SellerOverallScoreResponse) => {
          setRateData(successData.captured_data);
        },
      }
    );
  };

  return (
    <>
      <div className="my-5 flex flex-wrap sm:my-8">
        <div className="w-full px-0 pb-5 sm:w-4/12 sm:py-8 sm:pe-4 md:w-1/4 md:pe-5 ">
          <div className="flex items-center justify-center">
            <Rating
              name="simple-controlled"
              value={scoreData?.seller_score_average ?? 0}
              readOnly
              precision={0.25}
            />
            <div>{`(${scoreData?.number_of_scores ?? 0})`}</div>
          </div>
        </div>

        <Card className="w-full sm:w-8/12 md:w-3/4">
          <div className="mb-5">
            <Label>{t("form:input-label-review-status")}</Label>
            <Select
              onChange={(value: any) => {
                setReviewStatus(value);
              }}
              value={reviewStatus}
              getOptionLabel={(option: any) => option.name}
              getOptionValue={(option: any) => option.name}
              isLoading={isLoading}
              options={reviewStatuesList}
            />
          </div>

          <div
            className="mb-4 text-start"
            style={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <div
              className="mb-4 text-start"
              style={{
                display: "flex",
                justifyContent: "start",
              }}
            >
              <Button
                onClick={() => {
                  page === 1 ? getBusinessReviews() : setPage(1);
                }}
                loading={isLoading}
                disabled={isLoading}
              >
                {"Search"}
              </Button>
            </div>
          </div>

          <BusinessReviewsList
            businessReviewsList={businessReviewsList}
            paginatorInfo={{
              total: totalCount,
              currentPage: page,
              pageSize: PageSize,
            }}
            onPagination={onPagination}
          />
        </Card>
      </div>
    </>
  );
}
