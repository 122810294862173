import { Routes, Route } from "react-router-dom";
import { Routes as RoutesPath } from "../config/routes";
import Landing from "../views/landing";
import BaseLayout from "../components/layouts/base";
import SubscriptionPanel from "views/subscription/SubscriptionPanel";

const LandingRoutes = () => {
  return (
    <BaseLayout>
      <Routes>
        <Route path={RoutesPath.landing} element={<Landing />} />
        <Route path={RoutesPath.subscription} element={<SubscriptionPanel />} />
      </Routes>
    </BaseLayout>
  );
};

export default LandingRoutes;
