import BusinessReviewsFormView from "components/crm/BusinessReviewsForm";
import CommunicationMessagesFormView from "components/crm/CommunicationMessagesForm";
import React from "react";
import { useTranslation } from "react-i18next";

export default function BusinessReviewsView() {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex border-b border-dashed border-border-base py-5 sm:py-8">
        <h1 className="text-lg font-semibold text-heading">
          {`${t("common:sidebar-nav-item-crm")} / ${t(
            "common:sidebar-nav-sub-item-business-reviews"
          )}`}
        </h1>
      </div>
      <BusinessReviewsFormView />
    </>
  );
}
