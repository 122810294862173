import { Routes, Route } from "react-router-dom";
import { Routes as RoutesPath } from "../config/routes";
import AuthorizedRoute from "../components/AuthorizedRoute";
import TransactionsView from "views/finance/transactions/Transactions";

const FinanceRoutes = () => {
  return (
    <Routes>
      <Route
        path={RoutesPath.Transactions}
        element={
          <AuthorizedRoute userRole={""} route={null}>
            <TransactionsView />
          </AuthorizedRoute>
        }
      />
    </Routes>
  );
};

export default FinanceRoutes;
