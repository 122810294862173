import { Eye } from "components/icons/eye-icon";
import Pagination from "components/ui/pagination";
import { Table } from "components/ui/table";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import cn from "classnames";
import { useModalAction } from "provider/modal.context";
import { PaginatorInfoProps } from "types";
import { BusinessReview } from "types/businessReview";

type IProps = {
  businessReviewsList: BusinessReview[];
  paginatorInfo: PaginatorInfoProps | null;
  onPagination: (current: number) => void;
};

const BusinessReviewsList = ({
  businessReviewsList,
  paginatorInfo,
  onPagination,
}: IProps) => {
  const { openModal } = useModalAction();

  const { t } = useTranslation("common");

  const ReviewsColumns = useMemo(
    () => [
      {
        title: t("text-user"),
        dataIndex: "customer_id",
        key: "customer_id",
        align: "left",
        ellipsis: true,
        className: "!text-sm",
        width: 120,
      },
      {
        title: t("text-score"),
        dataIndex: "seller_score",
        key: "seller_score",
        align: "center",
        ellipsis: true,
        className: "!text-sm",
        width: 80,
      },
      {
        title: t("text-title"),
        dataIndex: "review_title",
        key: "review_title",
        align: "left",
        className: "!text-sm",
        width: 160,
      },
      {
        title: t("text-status"),
        dataIndex: "review_is_approved",
        key: "review_is_approved",
        align: "center",
        className: "!text-sm",
        width: 100,
        render: (value: any, row: BusinessReview) => (
          <div
            className={cn({
              "text-red-500": value === false,
              "text-green-500": value === true,
              "text-blue-500": value === null,
            })}
          >
            {value === null ? "New" : value === true ? "Approved" : "Rejected"}
          </div>
        ),
      },
      {
        title: t("text-details"),
        dataIndex: "action",
        key: "action",
        align: "center",
        className: "!text-sm",
        width: 50,
        render: (value: any, row: BusinessReview) => (
          <button
            name="details"
            onClick={() => {
              openModal("BUSINESS_REVIEW_DETAILS", {
                item: {
                  seller_score_details_id: row.ssd_id,
                },
                callback: () => {
                  onPagination(1);
                },
              });
            }}
            className="text-body transition duration-200 hover:text-heading focus:outline-none"
            title={t("text-details")}
          >
            <Eye width={16} />
          </button>
        ),
      },
    ],
    [t]
  );

  return (
    <>
      <div className="mb-6 overflow-hidden rounded shadow overflow-x-auto">
        <Table
          /* @ts-ignore */
          columns={ReviewsColumns}
          emptyText={t("table:empty-table-data")}
          data={businessReviewsList}
          rowKey="ssd_id"
          scroll={{ x: 900 }}
        />
      </div>
      {!!paginatorInfo?.total && (
        <div className="flex items-center justify-end">
          <Pagination
            total={paginatorInfo.total}
            current={paginatorInfo.currentPage}
            pageSize={paginatorInfo.pageSize}
            onChange={onPagination}
            showLessItems
          />
        </div>
      )}
    </>
  );
};

export default BusinessReviewsList;
