import { useMutation } from "@tanstack/react-query";
import client from "service/client";

export function useServiceBookingConditionsList() {
  const { mutate, isLoading } = useMutation(
    client.serviceBooking.getServiceBookingConditionsList
  );

  return { mutate, isLoading };
}

export function useServiceBookingConditionsDetails() {
  const { mutate, isLoading } = useMutation(
    client.serviceBooking.getServiceBookingConditionDetails
  );

  return { mutate, isLoading };
}

export function useServiceBookingSlotsList() {
  const { mutate, isLoading } = useMutation(
    client.serviceBooking.getServiceBookingSlotsList
  );

  return { mutate, isLoading };
}

export function useServiceBookingConditionDelete() {
  const { mutate, isLoading } = useMutation(
    client.serviceBooking.serviceBookingConditionDelete
  );

  return { mutate, isLoading };
}

export function useServiceBookingConditionSubmit() {
  const { mutate, isLoading } = useMutation(
    client.serviceBooking.serviceBookingConditionSubmit
  );

  return { mutate, isLoading };
}

export function useServiceBookingPreviewSlots() {
  const { mutate, isLoading } = useMutation(
    client.serviceBooking.serviceBookingPreviewSlots
  );

  return { mutate, isLoading };
}

export function useServiceBookingSlotsListWithPaging() {
  const { mutate, isLoading } = useMutation(
    client.serviceBooking.getServiceBookingSlotsListWithPaging
  );
  return { mutate, isLoading };
}

export function useServiceBookingSlotDetails() {
  const { mutate, isLoading } = useMutation(
    client.serviceBooking.getServiceBookingSlotDetails
  );

  return { mutate, isLoading };
}

export function useServiceBookingSlotClose() {
  const { mutate, isLoading } = useMutation(
    client.serviceBooking.serviceBookingSlotClose
  );

  return { mutate, isLoading };
}
export function useServiceBookingSlotComplete() {
  const { mutate, isLoading } = useMutation(
    client.serviceBooking.serviceBookingSlotComplete
  );

  return { mutate, isLoading };
}

export function useServiceBookingSlotRelease() {
  const { mutate, isLoading } = useMutation(
    client.serviceBooking.serviceBookingSlotRelease
  );

  return { mutate, isLoading };
}

export function useServiceBookingSlotCancel() {
  const { mutate, isLoading } = useMutation(
    client.serviceBooking.serviceBookingSlotCancel
  );

  return { mutate, isLoading };
}
export function useServiceBookingSlotAssign() {
  const { mutate, isLoading } = useMutation(
    client.serviceBooking.serviceBookingSlotAssign
  );

  return { mutate, isLoading };
}

export function useServiceBookingSlotBook() {
  const { mutate, isLoading } = useMutation(
    client.serviceBooking.serviceBookingSloBook
  );

  return { mutate, isLoading };
}
