import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import AuthorizedRoute from "../components/AuthorizedRoute";
import MainContextComponent from "../components/main-context";
import { Routes as RoutesPath } from "../config/routes";
import { LoginView } from "../views";
import BusinessView from "../views/business/BusinessView";
import FaqView from "../views/faq";
import GDPRView from "../views/gdpr";
import PolicyView from "../views/policy";
import RegisterView from "../views/register";
import PaymentResult from "../views/stripe/return";
import TermsView from "../views/terms";
import VerifyEmail from "../views/verify_email";
import DashboardRoutes from "./dashboard.routs";
import LandingRoutes from "./landing.routs";

const AppRoute = () => {
  // const RedirectToShop = () => {
  //   return <Navigate to={"/login"} />;
  // };
  return (
    <Suspense>
      <MainContextComponent>
        <Routes>
          <Route path={"/*"} element={<LandingRoutes />} />
          {/* <Route path={RoutesPath.landing} element={<Landing />} />
                    <Route
                        path={RoutesPath.PubSubscription}
                        element={<BusinessSetupSubscriptionView />}
                    /> */}
          <Route path={RoutesPath.login} element={<LoginView />} />
          <Route path={RoutesPath.register} element={<RegisterView />} />
          <Route path={RoutesPath.terms} element={<TermsView />} />
          <Route path={RoutesPath.privacy} element={<PolicyView />} />
          <Route path={RoutesPath.gdpr} element={<GDPRView />} />
          <Route path={RoutesPath.faq} element={<FaqView />} />
          <Route path={`${RoutesPath.business}`} element={<BusinessView />} />
          <Route
            path={`${RoutesPath.business}/:webUrl`}
            element={<BusinessView />}
          />
          {/* <Route
            path={RoutesPath.refresh}
            element={
              <AuthorizedRoute userRole={""} route={null}>
                <PrePayment />
              </AuthorizedRoute>
            }
          /> */}

          <Route
            path={RoutesPath.return}
            element={
              <AuthorizedRoute userRole={""} route={null}>
                <PaymentResult />
              </AuthorizedRoute>
            }
          />

          <Route
            path={RoutesPath.verifyEmail}
            element={
              <AuthorizedRoute userRole={""} route={null}>
                <VerifyEmail />
              </AuthorizedRoute>
            }
          />
          <Route path="/admin/*" element={<DashboardRoutes />} />
        </Routes>
      </MainContextComponent>
    </Suspense>
  );
};

export default AppRoute;
