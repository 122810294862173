import { adminAndOwnerOnly, adminOwnerAndStaffOnly } from "../utils/auth-utils";
import { Routes } from "../config/routes";

export const siteSettings = {
  name: "PickBazar",
  description: "",
  logo: {
    url: "/logo.svg",
    alt: "PickBazar",
    href: "/",
    width: 128,
    height: 40,
  },
  defaultLanguage: "en",
  author: {
    name: "RedQ, Inc.",
    websiteUrl: "https://redq.io",
    address: "",
  },
  headerLinks: [],
  authorizedLinks: [
    {
      href: Routes.profileUpdate,
      labelTransKey: "authorized-nav-item-profile",
    },
    {
      href: Routes.logout,
      labelTransKey: "authorized-nav-item-logout",
    },
  ],
  currencyCode: "USD",
  sidebarLinks: {
    admin: [
      {
        label: "sidebar-nav-item-dashboard",
        icon: "DashboardIcon",
        link: "/admin" + Routes.dashboard,
        href: "/admin" + Routes.dashboard,
      },
      {
        href: "#",
        label: "sidebar-nav-item-setup-business",
        icon: "DashboardIcon",

        children: [
          {
            href: Routes.BusinessSetupDetail,
            label: "sidebar-nav-sub-item-business-detail",
            icon: "SettingsIcon",
            link: `/admin${Routes.BusinessSetup}/${Routes.BusinessSetupDetail}`,
          },
          {
            href: Routes.BusinessSetupTeamOrMember,
            label: "sidebar-nav-sub-item-team-member",
            icon: "SettingsIcon",
            link: `/admin${Routes.BusinessSetup}/${Routes.BusinessSetupTeamOrMember}`,
          },
          {
            href: Routes.BusinessSetupBranch,
            label: "sidebar-nav-sub-item-branch-location",
            icon: "SettingsIcon",
            link: `/admin${Routes.BusinessSetup}/${Routes.BusinessSetupBranch}`,
          },
          {
            href: Routes.BusinessSetupType,
            label: "sidebar-nav-sub-item-business-type",
            icon: "SettingsIcon",
            link: `/admin${Routes.BusinessSetup}/${Routes.BusinessSetupType}`,
          },
          {
            href: Routes.BusinessSetupBankAccount,
            label: "sidebar-nav-sub-item-bank-account",
            icon: "SettingsIcon",
            link: `/admin${Routes.BusinessSetup}/${Routes.BusinessSetupBankAccount}`,
          },
          {
            href: Routes.BusinessSetupBusinessDocuments,
            label: "sidebar-nav-sub-item-business-documents",
            icon: "SettingsIcon",
            link: `/admin${Routes.BusinessSetup}/${Routes.BusinessSetupBusinessDocuments}`,
          },
          {
            href: Routes.BusinessSetupBusinessProfile,
            label: "sidebar-nav-sub-item-business-profile",
            icon: "SettingsIcon",
            link: `/admin${Routes.BusinessSetup}/${Routes.BusinessSetupBusinessProfile}`,
          },
          {
            href: Routes.BusinessSetupBusinessLogo,
            label: "sidebar-nav-sub-item-business-logo",
            icon: "SettingsIcon",
            link: `/admin${Routes.BusinessSetup}/${Routes.BusinessSetupBusinessLogo}`,
          },
          {
            href: Routes.BusinessSetupSubscription,
            label: "sidebar-nav-sub-item-subscription",
            icon: "SettingsIcon",
            link: `/admin${Routes.BusinessSetup}/${Routes.BusinessSetupSubscription}`,
          },
          {
            href: "#",
            label: "sidebar-nav-sub-item-interview-checking",
            icon: "SettingsIcon",
          },
        ],
      },
      {
        href: "#",
        label: "sidebar-nav-item-setup-in-person",
        icon: "ShopIcon",

        children: [
          {
            href: Routes.BusinessInPersonServices,
            label: "sidebar-nav-sub-item-in-person-service",
            icon: "SettingsIcon",
            link: `/admin${Routes.BusinessInPerson}/${Routes.BusinessInPersonServices}`,
          },
          {
            href: Routes.BusinessStoryFile,
            label: "sidebar-nav-sub-item-business-story-file",
            icon: "SettingsIcon",
            link: `/admin${Routes.BusinessInPerson}/${Routes.BusinessStoryFile}`,
          },
        ],
      },
      {
        href: "#",
        label: "sidebar-nav-item-service-booking",
        icon: "ShopIcon",

        children: [
          {
            href: Routes.ManageAvailability,
            label: "sidebar-nav-sub-item-manage-availability",
            icon: "SettingsIcon",
            link: `/admin${Routes.ServiceBooking}/${Routes.ManageAvailability}`,
          },
          {
            href: Routes.BookingManagement,
            label: "sidebar-nav-sub-item-booking-management",
            icon: "SettingsIcon",
            link: `/admin${Routes.ServiceBooking}/${Routes.BookingManagement}`,
          },
        ],
      },
      {
        href: "#",
        label: "sidebar-nav-item-product-management",
        icon: "ShopIcon",
        children: [
          {
            href: Routes.ProductManagementPolicy,
            label: "sidebar-nav-sub-item-product-policy",
            icon: "SettingsIcon",
            link: `/admin${Routes.ProductManagement}/${Routes.ProductManagementPolicy}`,
          },
          {
            href: Routes.ProductManagementImage,
            label: "sidebar-nav-sub-item-product-image",
            icon: "SettingsIcon",
            link: `/admin${Routes.ProductManagement}/${Routes.ProductManagementImage}`,
          },
          {
            href: Routes.ProductManagementVideo,
            label: "sidebar-nav-sub-item-product-video",
            icon: "SettingsIcon",
            link: `/admin${Routes.ProductManagement}/${Routes.ProductManagementVideo}`,
          },
          {
            href: Routes.ProductManagementInventory,
            label: "sidebar-nav-sub-item-product-inventory",
            icon: "SettingsIcon",
            link: `/admin${Routes.ProductManagement}/${Routes.ProductManagementInventory}`,
          },
          {
            href: Routes.ProductManagementOffer,
            label: "sidebar-nav-sub-item-product-offer",
            icon: "SettingsIcon",
            link: `/admin${Routes.ProductManagement}/${Routes.ProductManagementOffer}`,
          },
          {
            href: Routes.ProductManagementGroup,
            label: "sidebar-nav-sub-item-product-group",
            icon: "SettingsIcon",
            link: `/admin${Routes.ProductManagement}/${Routes.ProductManagementInventoryGroup}`,
          },
        ],
      },
      {
        href: "#",
        label: "sidebar-nav-item-product-listings",
        icon: "ProductsIcon",
        children: [
          {
            href: Routes.ProductListingItems,
            label: "sidebar-nav-sub-item-product-listings",
            icon: "SettingsIcon",
            link: `/admin${Routes.ProductListing}/${Routes.ProductListingItems}`,
          },
          {
            href: Routes.ProductListingPublishByOffer,
            label: "sidebar-nav-sub-item-product-publish-by-offer",
            icon: "SettingsIcon",
            link: `/admin${Routes.ProductListing}/${Routes.ProductListingPublishByOffer}`,
          },
          {
            href: Routes.ProductListingPublishByProductGroup,
            label: "sidebar-nav-sub-item-product-publish-group",
            icon: "SettingsIcon",
            link: `/admin${Routes.ProductListing}/${Routes.ProductListingPublishByProductGroup}`,
          },
        ],
      },
      {
        href: Routes.attribute.list,
        label: "sidebar-nav-item-shippings",
        icon: "AttributeIcon",
      },
      {
        href: Routes.type.list,
        label: "sidebar-nav-item-orders",
        icon: "TypesIcon",
      },
      {
        href: Routes.tag.list,
        label: "sidebar-nav-item-refunds",
        icon: "TagIcon",
      },
      {
        href: "#",
        label: "sidebar-nav-item-finance",
        icon: "TagIcon",
        children: [
          {
            href: Routes.Transactions,
            label: "sidebar-nav-sub-item-transactions",
            icon: "SettingsIcon",
            link: `/admin${Routes.Finance}/${Routes.Transactions}`,
          },
          {
            href: "#",
            label: "sidebar-nav-sub-item-finance-credibility",
            icon: "SettingsIcon",
            // link: `${Routes.ProductManagement}/${Routes.ProductManagementPolicy}`
            link: `#`,
          },
          {
            href: "#",
            label: "sidebar-nav-sub-item-finance-accounting-service",
            icon: "SettingsIcon",
            // link: `${Routes.ProductManagement}/${Routes.ProductManagementPolicy}`
            link: `#`,
          },
          {
            href: "#",
            label: "sidebar-nav-sub-item-finance-transactions-payments",
            icon: "SettingsIcon",
            // link: `${Routes.ProductManagement}/${Routes.ProductManagementPolicy}`
            link: `#`,
          },
        ],
      },
      {
        href: "#",
        label: "sidebar-nav-item-ai-copilot",
        icon: "DiaryIcon",
        children: [
          {
            href: Routes.AICopilot,
            label: "sidebar-nav-sub-item-ai-description-generator",
            icon: "SettingsIcon",
            link: `/admin${Routes.AICopilot}/${Routes.AICopilotDescriptionGenerator}`,
          },
          {
            href: Routes.AICopilot,
            label: "sidebar-nav-sub-item-ai-ad-content-compaign",
            icon: "SettingsIcon",
            link: `/admin${Routes.AICopilot}/${Routes.AICopilotAdContent}`,
          },
          {
            href: Routes.AICopilot,
            label: "sidebar-nav-sub-item-ai-site-content-generator",
            icon: "SettingsIcon",
            link: `/admin${Routes.AICopilot}/${Routes.AICopilotSiteContent}`,
          },
        ],
      },
      {
        href: "#",
        label: "sidebar-nav-item-crm",
        icon: "FountainPenIcon",
        children: [
          {
            href: Routes.CRMCustomerMessages,
            icon: "SettingsIcon",
            label: "sidebar-nav-sub-item-customer-messages",
            link: `/admin${Routes.CRM}${Routes.CRMCustomerMessages}`,
          },
          {
            href: Routes.CRMBusinessReviews,
            icon: "SettingsIcon",
            label: "sidebar-nav-sub-item-business-reviews",
            link: `/admin${Routes.CRM}${Routes.CRMBusinessReviews}`,
          },
          {
            href: "#",
            label: "sidebar-nav-sub-item-message-question",
            icon: "SettingsIcon",
          },
          {
            href: "#",
            label: "sidebar-nav-sub-item-customer-support",
            icon: "SettingsIcon",
          },
          {
            href: "#",
            label: "sidebar-nav-sub-item-reviews",
            icon: "SettingsIcon",
          },
        ],
      },
      {
        href: Routes.order.list,
        label: "sidebar-nav-item-supply-chain-mng",
        icon: "OrdersIcon",
      },
      {
        href: Routes.order.create,
        label: "sidebar-nav-item-b2b",
        icon: "CalendarScheduleIcon",
      },
      {
        href: "#",
        label: "sidebar-nav-item-ai-driven-business",
        icon: "UsersIcon",
        children: [
          {
            href: "#",
            label: "sidebar-nav-sub-item-recommendations",
            icon: "SettingsIcon",
          },
          {
            href: Routes.AIAnalyticsDescriptionGenerator,
            label: "sidebar-nav-sub-item-description-generator",
            icon: "SettingsIcon",
            link: `/admin${Routes.AIAnalytics}/${Routes.AIAnalyticsDescriptionGenerator}`,
          },
          {
            href: "#",
            label: "sidebar-nav-sub-item-customer-segmentation",
            icon: "SettingsIcon",
          },
          {
            href: "#",
            label: "sidebar-nav-sub-item-ranking-and-reviews",
            icon: "SettingsIcon",
          },
          {
            href: "#",
            label: "sidebar-nav-sub-item-pricing-optimization",
            icon: "SettingsIcon",
          },
          {
            href: "#",
            label: "sidebar-nav-sub-item-sales-forecasting",
            icon: "SettingsIcon",
          },
          {
            href: "#",
            label: "sidebar-nav-sub-item-customer-value",
            icon: "SettingsIcon",
          },
          {
            href: "#",
            label: "sidebar-nav-sub-item-market-insights",
            icon: "SettingsIcon",
          },
          {
            href: "#",
            label: "sidebar-nav-sub-item-business-intelligence-reports",
            icon: "SettingsIcon",
          },
          {
            href: "#",
            label: "sidebar-nav-sub-item-graph-mining-analytics",
            icon: "SettingsIcon",
          },
          {
            href: "#",
            label: "sidebar-nav-sub-item-sales-analysis-per-products",
            icon: "SettingsIcon",
          },
          {
            href: "#",
            label: "sidebar-nav-sub-item-sales-analysis-per-location",
            icon: "SettingsIcon",
          },
          {
            href: "#",
            label: "sidebar-nav-sub-item-products-reports",
            icon: "SettingsIcon",
          },
          {
            href: "#",
            label: "sidebar-nav-sub-item-financial-analysis",
            icon: "SettingsIcon",
          },
        ],
      },
      {
        href: Routes.settings,
        label: "sidebar-nav-item-settings",
        icon: "SettingsIcon",
      },
    ],
    shop: [
      {
        href: (shop: string) => `${Routes.dashboard}${shop}`,
        label: "sidebar-nav-item-dashboard",
        icon: "DashboardIcon",
        permissions: adminOwnerAndStaffOnly,
      },
      {
        href: (shop: string) => `/${shop}${Routes.attribute.list}`,
        label: "sidebar-nav-item-attributes",
        icon: "AttributeIcon",
        permissions: adminOwnerAndStaffOnly,
      },
      {
        href: (shop: string) => `/${shop}${Routes.product.list}`,
        label: "sidebar-nav-item-products",
        icon: "ProductsIcon",
        permissions: adminOwnerAndStaffOnly,
      },
      {
        href: (shop: string) => `/${shop}${Routes.author.list}`,
        label: "sidebar-nav-item-authors",
        icon: "FountainPenIcon",
        permissions: adminAndOwnerOnly,
      },
      {
        href: (shop: string) => `/${shop}${Routes.manufacturer.list}`,
        label: "sidebar-nav-item-manufacturers",
        icon: "DiaryIcon",
        permissions: adminAndOwnerOnly,
      },
      {
        href: (shop: string) => `/${shop}${Routes.order.list}`,
        label: "sidebar-nav-item-orders",
        icon: "OrdersIcon",
        permissions: adminOwnerAndStaffOnly,
      },
      {
        href: (shop: string) => `/${shop}${Routes.refund.list}`,
        label: "sidebar-nav-item-refunds",
        icon: "RefundsIcon",
        permissions: adminOwnerAndStaffOnly,
      },
      {
        href: (shop: string) => `/${shop}${Routes.staff.list}`,
        label: "sidebar-nav-item-staffs",
        icon: "UsersIcon",
        permissions: adminAndOwnerOnly,
      },
      {
        href: (shop: string) => `/${shop}${Routes.withdraw.list}`,
        label: "sidebar-nav-item-withdraws",
        icon: "AttributeIcon",
        permissions: adminAndOwnerOnly,
      },
      {
        href: (shop: string) => `/${shop}${Routes.reviews.list}`,
        label: "sidebar-nav-item-reviews",
        icon: "ReviewIcon",
        permissions: adminAndOwnerOnly,
      },
      {
        href: (shop: string) => `/${shop}${Routes.question.list}`,
        label: "sidebar-nav-item-questions",
        icon: "QuestionIcon",
        permissions: adminAndOwnerOnly,
      },
      {
        href: (shop: string) => `/${shop}${Routes.storeNotice.list}`,
        label: "sidebar-nav-item-store-notice",
        icon: "StoreNoticeIcon",
        permissions: adminAndOwnerOnly,
      },
    ],
  },
  product: {
    placeholder: "/product-placeholder.svg",
  },
  avatar: {
    placeholder: "/avatar-placeholder.svg",
  },
};
