import { Routes, Route } from "react-router-dom";
import { Routes as RoutesPath } from "../config/routes";
import AuthorizedRoute from "../components/AuthorizedRoute";
import CommunicationMessageView from "views/crm/communication-message/CommunicationMessage";
import CommunicationMessageDetailsView from "views/crm/communication-message/CommunicationMessageDetails";
import BusinessReviewsView from "views/crm/business-reviews/BusinessReviews";

const CRMRoutes = () => {
  return (
    <Routes>
      <Route
        path={RoutesPath.CRMCustomerMessages}
        element={
          <AuthorizedRoute userRole={""} route={null}>
            <CommunicationMessageView />
          </AuthorizedRoute>
        }
      />
      <Route
        path={RoutesPath.CRMCustomerMessageDetails}
        element={
          <AuthorizedRoute userRole={""} route={null}>
            <CommunicationMessageDetailsView />
          </AuthorizedRoute>
        }
      />
      <Route
        path={RoutesPath.CRMBusinessReviews}
        element={
          <AuthorizedRoute userRole={""} route={null}>
            <BusinessReviewsView />
          </AuthorizedRoute>
        }
      />
    </Routes>
  );
};

export default CRMRoutes;
