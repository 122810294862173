import cn from "classnames";
import { CloseIcon } from "components/icons/close-icon";
import Button from "components/ui/button";
import Loader from "components/ui/loader/loader";
import { Routes } from "config/routes";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { API_ENDPOINTS } from "service/client/api-endpoints";
import { HttpClient } from "service/client/http-client";
import {
  useBusinessProfileByIdShow,
  useSubscriptionBusinessShow,
} from "service/hook/business";
import Currency from "utils/constants/currencyObj.json";
import {
  featuresItems,
  tiresFeaturesItems,
} from "views/subscription/SubscriptionPanel";
import { userStore } from "../../store";
import {
  BusinessSetupBusinessProfileByIdShowItem,
  BusinessSetupBusinessProfileByIdShowReq,
  BusinessSetupBusinessProfileByIdShowResponse,
  SubscriptionFees,
  SubscriptionFeesShowResponse,
  SubscriptionPanelType,
} from "../../types";
import { CheckMark } from "../icons/checkmark";
import Card from "../ui/cards/card";
import Radio from "../ui/radio/radio";

const businessType: Record<string, string> = {
  in_person_service: "1",
  online_service: "2",
  hybrid_service: "3",
};

export default function SubscriptionForm2() {
  const location = useLocation();
  const navigate = useNavigate();
  const { businessItem } = userStore((state) => state);

  const [message, setMessage] = useState<string>("");
  const [panelDetails, setPanelDetails] = useState<any>();
  const [subscriptionFees, setSubscriptionFees] = useState<SubscriptionFees[]>(
    []
  );
  const [panelType, setPanelType] = useState<string>(
    SubscriptionPanelType.Monthly
  );
  const [businessProfileDetails, setBusinessProfileDetails] =
    useState<BusinessSetupBusinessProfileByIdShowItem>();
  const { t } = useTranslation("common");

  const {
    mutate: mutateSubscriptionBusinessShow,
    isLoading: isLoadingSubscriptionBusinessShow,
  } = useSubscriptionBusinessShow();

  const {
    mutate: mutateBusinessProfileByIdShow,
    isLoading: isLoadingBusinessProfileByIdShow,
  } = useBusinessProfileByIdShow();

  const handlePay = (tiresItems: any, subFee?: SubscriptionFees) => {
    navigate(
      `/admin${Routes.BusinessSetup}/${Routes.BusinessSetupPaymentSubscription}`,
      {
        state: {
          currency: "GBP",
          tierCode: tiresItems.tierCode,
          price: subFee?.subscription_monthly_fee,
          interval:
            panelType == SubscriptionPanelType.Monthly ? "monthly" : "yearly",
        },
      }
    );
  };

  const getSubscriptionBusinessShow = () => {
    mutateSubscriptionBusinessShow(
      {
        businessdetail_id: `${businessItem?.business_id}`,
      },
      {
        onSuccess: (successData: any) => {
          if (successData.final_result_code === "000") {
            setPanelDetails(successData.captured_data);
          }
        },
        onError: (errorData: any) => {
          if (errorData.request?.status === 404) {
            setMessage(t("text-description-subscription-no-plan"));
          } else {
            setMessage("");
          }
        },
      }
    );
  };
  const getBusinessProfileByIdShowItem = () => {
    const input: BusinessSetupBusinessProfileByIdShowReq = {
      businessdetail_id: `${businessItem?.business_id ?? 0}`,
    };

    mutateBusinessProfileByIdShow(input, {
      onSuccess: (
        successData: BusinessSetupBusinessProfileByIdShowResponse
      ) => {
        if (successData.final_result_code === "000") {
          if (
            !successData.captured_data.business_type ||
            successData.captured_data.business_type === ""
          ) {
            setMessage(t("text-description-subscription-not-business-type"));
          }
          setBusinessProfileDetails(successData.captured_data);
          const newTires = tiresFeaturesItems.filter(
            (item) =>
              !item.type ||
              item.type ===
                businessType[successData.captured_data.business_type]
          );
          newTires.forEach(async (item) => {
            const response = await getSubscriptionFeesShow(item.tierCode);
            setSubscriptionFees((prev) => [...prev, ...response.captured_data]);
          });
        }
      },
      onError: (errorData: any) => {
        if (
          errorData.request?.status === 400 ||
          errorData.request?.status === 404
        ) {
          toast.error(errorData.response?.data?.general_result);
          setMessage("");
          setBusinessProfileDetails(undefined);
        }
      },
    });
  };

  const getSubscriptionFeesShow = (tireCode: string) => {
    return HttpClient.post<SubscriptionFeesShowResponse>(
      `${API_ENDPOINTS.SUBSCRIPTION_BUSINESS_FEE_SHOW}`,
      {
        tier_code: tireCode,
      }
    );
  };

  useEffect(() => {
    getBusinessProfileByIdShowItem();
    getSubscriptionBusinessShow();
  }, [businessItem]);

  return (
    <>
      {isLoadingSubscriptionBusinessShow || isLoadingBusinessProfileByIdShow ? (
        <Loader className="!h-full flex-1" text={t("common:text-loading")} />
      ) : (
        businessProfileDetails && (
          <div className="my-2 flex flex-col flex-wrap">
            {message !== "" && (
              <div className="font-bold p-3 bg-amber-300 rounded-md mb-4">
                {message}
              </div>
            )}
            {!businessProfileDetails.business_type ||
            businessProfileDetails.business_type === "" ? (
              <div className="my-2 flex justify-center items-center">
                <Button
                  className="bg-accent hover:bg-accent-hover"
                  onClick={() =>
                    navigate(
                      `/admin${Routes.BusinessSetup}/${Routes.BusinessSetupType}`
                    )
                  }
                >
                  Business Type
                </Button>
              </div>
            ) : (
              <Card className="!p-2 w-full sm:w-8/12 md:w-full bg-sky-50">
                <div className={"w-full flex items-center space-x-8 py-3"}>
                  <Radio
                    label={"Monthly"}
                    name="panelType"
                    checked={panelType === SubscriptionPanelType.Monthly}
                    id="panelType1"
                    value={SubscriptionPanelType.Monthly}
                    onChange={(event) => setPanelType(event.target.value)}
                  />
                  <Radio
                    label={"Yearly"}
                    name="panelType"
                    id="panelType2"
                    value={SubscriptionPanelType.Yearly}
                    checked={panelType === SubscriptionPanelType.Yearly}
                    onChange={(event) => setPanelType(event.target.value)}
                  />
                </div>
                <div className="w-full flex gap-2">
                  <div className="flex flex-col w-[40%]">
                    <div
                      className={cn(
                        "flex text-slate-900 text-sm font-bold gap-2 bg-white"
                      )}
                    >
                      <div className="px-4 border-r-4 border-slate-100 w-12"></div>
                      <div className={`text-center w-full`}>Features</div>
                    </div>
                    {featuresItems.map((mapItem, index) => {
                      return (
                        <div
                          className={cn(
                            "flex text-slate-900 text-sm gap-2",
                            index % 2 === 0 ? " bg-blue-100" : "bg-slate-100"
                          )}
                        >
                          <div className="px-3 border-r-4 border-slate-100 w-12 text-center">
                            {index + 1}
                          </div>
                          <div className={`text-left`}>{mapItem}</div>
                        </div>
                      );
                    })}
                    <div className="flex bg-orange-200 text-sm gap-2 mt-1">
                      <div className="px-4 border-r-4 w-12"></div>
                      <div className="font-bold text-sm text-center bg-orange-200">
                        Base Fee
                      </div>
                    </div>
                    <div className="flex bg-orange-200 text-sm gap-2 mt-1">
                      <div className="px-4 border-r-4 w-12"></div>
                      <div className="font-bold text-sm text-center bg-orange-200">
                        VAT
                      </div>
                    </div>
                    {panelType === SubscriptionPanelType.Monthly && (
                      <div className="flex bg-orange-200 text-sm gap-2 mt-1">
                        <div className="px-4 border-r-4 w-12"></div>
                        <div className="font-bold text-sm text-center bg-orange-200">
                          Total Monthly
                        </div>
                      </div>
                    )}
                    {panelType === SubscriptionPanelType.Yearly && (
                      <div className="flex bg-orange-200 text-sm gap-2 mt-1">
                        <div className="px-4 border-r-4 w-12"></div>
                        <div className="font-bold text-sm text-center bg-orange-200">
                          Total Yearly
                        </div>
                      </div>
                    )}
                    <div
                      className={cn(
                        "flex text-slate-900 text-sm font-bold gap-2 bg-white"
                      )}
                    >
                      <div className="px-4 border-r-4 border-slate-100 w-12"></div>
                      <div className="py-2 flex justify-center items-center bg-white h-9"></div>
                    </div>
                  </div>

                  {tiresFeaturesItems.map((mapItem, index) => {
                    const selectedSubFee = subscriptionFees.find(
                      (sub) => sub.tier_code === +mapItem.tierCode
                    );
                    return (
                      (!mapItem.type ||
                        mapItem.type ===
                          businessType[
                            businessProfileDetails?.business_type
                          ]) && (
                        <div
                          className="flex gap-2 w-[12%]"
                          key={`feat-${index}`}
                        >
                          <div className="flex flex-col w-full">
                            <div
                              className={`text-center w-full bg-white font-bold text-sm`}
                            >
                              {mapItem.title}
                            </div>
                            {mapItem.services.map(
                              (serviceItem, indexServiceItem) => {
                                return (
                                  <div
                                    className={`text-lg text-center text-slate-900 h-5 ${
                                      indexServiceItem % 2 === 0
                                        ? " bg-blue-100"
                                        : "bg-slate-100"
                                    }`}
                                  >
                                    {typeof serviceItem === "boolean" ? (
                                      <div
                                        className={cn(
                                          "flex w-full text-center justify-center",
                                          serviceItem
                                            ? "text-green-500"
                                            : "text-red-500"
                                        )}
                                      >
                                        {serviceItem ? (
                                          <CheckMark width={14} />
                                        ) : (
                                          <CloseIcon width={14} />
                                        )}
                                      </div>
                                    ) : (
                                      <>
                                        <div className="flex gap-3 justify-center items-center text-sm">
                                          {serviceItem.value ? (
                                            <>
                                              <div className="text-green-500">
                                                <CheckMark width={14} />
                                              </div>
                                              <div>{serviceItem?.title}</div>
                                            </>
                                          ) : (
                                            <>
                                              <div className="text-red-500">
                                                <CloseIcon width={14} />
                                              </div>
                                              <div>{serviceItem?.title}</div>
                                            </>
                                          )}
                                        </div>
                                      </>
                                    )}
                                  </div>
                                );
                              }
                            )}
                            <div className="font-bold text-sm text-center bg-orange-200 mt-1">
                              {selectedSubFee?.subscription_base_fee}
                              {(Currency as any)["GBP"]}
                            </div>
                            <div className="font-bold text-sm text-center bg-orange-200 mt-1">
                              {selectedSubFee?.subscription_vat_applied}
                              {(Currency as any)["GBP"]}
                            </div>
                            {panelType === SubscriptionPanelType.Monthly && (
                              <div className="font-bold text-sm text-center bg-orange-200 mt-1">
                                {selectedSubFee?.subscription_monthly_fee}
                                {(Currency as any)["GBP"]}
                              </div>
                            )}
                            {panelType === SubscriptionPanelType.Yearly && (
                              <div className="font-bold text-sm text-center bg-orange-200 mt-1">
                                {selectedSubFee?.subscription_yearly_fee}
                                {(Currency as any)["GBP"]}
                              </div>
                            )}
                            <div className="py-1 flex justify-center items-center bg-white">
                              <Button
                                type="button"
                                className={`!h-7 font-bold text-sm`}
                                onClick={() =>
                                  handlePay(mapItem, selectedSubFee)
                                }
                              >
                                {t("text-pay")}
                              </Button>
                            </div>
                          </div>
                        </div>
                      )
                    );
                  })}
                </div>
                <Button
                  type="button"
                  className={`!h-9 font-bold text-sm !w-max mt-4 bg-cyan-500 !border-cyan-500 cursor-pointer hover:bg-cyan-600 `}
                  onClick={() =>
                    window.open(
                      `${process.env.PUBLIC_URL}/files/subscription_guidance.html`,
                      "_blank"
                    )
                  }
                >
                  Subscription guidance
                </Button>
              </Card>
            )}
          </div>
        )
      )}
    </>
  );
}
