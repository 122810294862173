import Loader from "components/ui/loader/loader";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import cn from "classnames";
import { useModalAction } from "provider/modal.context";
import { Routes } from "config/routes";
import {
  CommunicationMessage,
  MessagesDetails,
  MessagesDetailsRequestModel,
  MessagesDetailsResponseModel,
} from "types/communicationMessage";
import { useMessageDetails } from "service/hook/communicationMessage.hook";
import ArrowNarrowLeft from "components/icons/arrow-narrow-left";

const CommunicationMessageDetailsView = () => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const [messagesDetails, setMessagesDetails] = useState<MessagesDetails[]>([]);
  const [communication, setCommunication] = useState<CommunicationMessage>();
  const location = useLocation();

  const { mutate: mutateMessageDetails, isLoading } = useMessageDetails();
  const { openModal } = useModalAction();

  const getMessageDetails = () => {
    const input: MessagesDetailsRequestModel = {
      communication_id: location?.state?.communication_id,
      businessdetail_id: location?.state?.businessdetail_id,
    };

    mutateMessageDetails(input, {
      onSuccess: (successData: MessagesDetailsResponseModel) => {
        if (successData.final_result_code === "000") {
          setMessagesDetails(successData.captured_data_messages_details);
          setCommunication(successData.captured_data_communication);
        }
      },
    });
  };

  useEffect(() => {
    getMessageDetails();
  }, []);

  return (
    <>
      <div className="flex border-b border-dashed border-border-base py-5 sm:py-8">
        <h1 className="text-lg font-semibold text-heading">
          {`${t("common:sidebar-nav-item-crm")} / ${t(
            "common:sidebar-nav-sub-item-customer-messages"
          )} / ${t("common:text-customer-message-details")}`}
        </h1>
      </div>
      <div className="mx-auto flex w-full max-w-7xl flex-col px-5 pb-20 md:flex-row md:pb-10 xl:px-8 xl:pb-14 2xl:px-14">
        <div className="flex h-full flex-col w-full">
          <div className="flex justify-end items-center mt-6">
            <div className="flex gap-4">
              <button
                onClick={() => {
                  openModal("REPLY_MESSAGE", {
                    item: {
                      businessdetail_id: location?.state?.businessdetail_id,
                      communication_id: location?.state?.communication_id,
                      customer_email: location?.state?.customer_email,
                    },
                    callback: () => {
                      getMessageDetails();
                    },
                  });
                }}
                data-variant="normal"
                className="inline-flex items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-0 focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-accent-hover px-5 py-0 h-12"
              >
                {t("text-reply")}
              </button>
              <button
                className="inline-flex items-center justify-center font-semibold text-accent transition-colors hover:text-accent-hover focus:text-accent-hover focus:outline-0"
                onClick={() =>
                  navigate(`/admin${Routes.CRM}${Routes.CRMCustomerMessages}`)
                }
              >
                <ArrowNarrowLeft
                  className={cn("h-5 w-5 ltr:mr-2 rtl:ml-2", {
                    "rotate-180 transform": "he",
                  })}
                  strokeWidth={1.7}
                />
                {t("button-label-back")}
              </button>
            </div>
          </div>
          <div></div>
          {isLoading ? (
            <Loader
              className="!h-full flex-1"
              text={t("common:text-loading")}
            />
          ) : (
            <>
              <div className="flex items-center justify-between py-3 px-5 md:px-3 lg:px-5 ">
                <div className="flex gap-3">
                  <span className="flex shrink-0 text-sm font-bold text-heading ltr:mr-4 rtl:ml-4 lg:text-base">
                    {t("text-title")}:
                  </span>
                  <span className="font-normal">{communication?.title}</span>
                </div>
                <div className="flex gap-3">
                  <span className="flex shrink-0 text-sm font-bold text-heading ltr:mr-4 rtl:ml-4 lg:text-base">
                    {t("text-message-business")}:
                  </span>
                  <span className="font-normal">
                    {communication?.business_name}
                  </span>
                </div>
              </div>
              {messagesDetails.length ? (
                messagesDetails.map(
                  (message: MessagesDetails, index: number) => (
                    <div
                      key={index}
                      className={cn(
                        "mb-4 flex w-full shrink-0 cursor-pointer flex-col rounded border-2 border-transparent bg-white last:mb-0"
                      )}
                    >
                      <div className="flex flex-col p-5 md:p-3 lg:px-4 lg:py-5">
                        <p className="mb-4 flex w-full items-center text-sm text-heading last:mb-0 gap-4">
                          <span className="shrink-0 font-bold">
                            {t("text-customer-email")} :
                          </span>
                          <span className="ltr:ml-1 rtl:mr-1">
                            {message.customer_email}
                          </span>
                        </p>
                        <p className="mb-4 flex w-full items-center text-sm text-heading last:mb-0 gap-4">
                          <span className="shrink-0 font-bold">
                            {t("text-message-sent-at")} :
                          </span>
                          <span className="ltr:ml-1 rtl:mr-1">
                            {message._sent_at}
                          </span>
                        </p>
                        <p className="mb-4 flex w-full items-center text-sm text-heading last:mb-0 gap-4">
                          <span className="shrink-0 font-bold">
                            {t("text-message-content")} :
                          </span>
                          <span className="ltr:ml-1 rtl:mr-1">
                            {message.message_content}
                          </span>
                        </p>
                      </div>
                    </div>
                  )
                )
              ) : (
                <div
                  className={cn(
                    "mb-4 flex w-full justify-center items-center rounded border-2 border-transparent bg-white last:mb-0 p-5 font-bold"
                  )}
                >
                  {t("table:empty-table-data")}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default CommunicationMessageDetailsView;
