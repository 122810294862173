import React, { useState } from "react";
import cn from "classnames";
import Logo from "../ui/logo";
import JoinButton from "../layouts/menu/join-button";
import { useTranslation } from "react-i18next";
import { authStore, userStore } from "../../store";
import AuthorizedMenu from "../layouts/navigation/authorized-menu";

const Header = ({ layout }: { layout?: string }) => {
    const { t } = useTranslation("common");

    const { accessToken, email } = authStore((state) => state);
    const userData: any = userStore((state) => state);
    const [openDropdown, setOpenDropdown] = useState(false);

    const isAuthorize = () => {
        if (userData?.user) {
            if (accessToken) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };
    const isFlattenHeader = false;

    const closeLocation = () => setOpenDropdown(false);

    return (
        <header
            className={cn("site-header-with-search top-0 z-50 w-full lg:h-22", {
                "": isFlattenHeader,
                "sticky lg:fixed": true,
                "sticky border-b border-border-200 shadow-sm": !true,
            })}
        >
            <div
                className={cn(
                    "fixed inset-0 -z-10 h-[100vh] w-full bg-black/50",
                    openDropdown === true ? "" : "hidden"
                )}
                onClick={closeLocation}
            ></div>
            <div>
                <div
                    className={cn(
                        " flex w-full transform-gpu items-center justify-between bg-light transition-transform duration-300 lg:h-22 lg:px-4 xl:px-8",
                        {
                            "lg:absolute lg:border-0 lg:bg-transparent lg:shadow-none":
                                isFlattenHeader,
                            "lg:!bg-light": openDropdown,
                        }
                    )}
                >
                    <div className="flex w-full shrink-0 grow-0 basis-auto flex-col items-center lg:w-auto lg:flex-row">
                        <Logo
                            className={cn("pt-2 pb-3", !true ? "lg:mx-0" : "ltr:ml-0 rtl:mr-0")}
                        />
                    </div>
                    <ul className="hidden shrink-0 items-center space-x-7 rtl:space-x-reverse lg:flex 2xl:space-x-10">
                        {/* <StaticMenu /> */}
                        <div className="flex items-center space-x-4 rtl:space-x-reverse">
                            <a
                                href={`${process.env.NEXT_PUBLIC_ADMIN_URL}/register`}
                                target="_blank"
                                rel="noreferrer"
                                className="inline-flex h-9 shrink-0 items-center justify-center rounded border border-transparent bg-accent px-3 py-0 text-sm font-semibold leading-none text-light outline-none transition duration-300 ease-in-out hover:bg-accent-hover focus:shadow focus:outline-none focus:ring-1 focus:ring-accent-700"
                            >
                                {t("text-become-seller")}
                            </a>
                            <li
                                style={{
                                    direction: "rtl",
                                }}
                            >
                                {isAuthorize() ? <AuthorizedMenu /> : <JoinButton />}
                            </li>
                        </div>
                    </ul>
                </div>
                <div
                    className={cn(
                        "w-full border-b border-t border-border-200 bg-light shadow-sm",
                        true ? "hidden lg:block" : "hidden"
                    )}
                ></div>
            </div>
        </header>
    );
};

export default Header;
