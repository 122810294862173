import { useTranslation } from "react-i18next";

import cn from "classnames";
import Pagination from "components/ui/pagination";
import { useModalAction } from "provider/modal.context";
import Table from "rc-table";
import { PaginatorInfoProps, SlotStatus, SortOrder } from "types";
import { ServiceBookingSlot } from "types/serviceBooking";
import Button from "components/ui/button";

export type IProps = {
  serviceBookingSlotsList: ServiceBookingSlot[] | undefined;
  paginatorInfo: PaginatorInfoProps | null;
  onPagination: (current: number) => void;
  onDeleteItem: (item: ServiceBookingSlot) => void;
  onReleaseItem: (item: ServiceBookingSlot) => void;
};

const BookingManagementList = ({
  serviceBookingSlotsList,
  paginatorInfo,
  onPagination,
  onDeleteItem,
  onReleaseItem,
}: IProps) => {
  const { t } = useTranslation();
  const { openModal } = useModalAction();

  const columns = [
    {
      title: t("table:table-record-id"),
      dataIndex: "service_booking_record_id",
      key: "service_booking_record_id",
      width: 90,
      align: "center",
    },
    {
      title: t("table:table-book-date"),
      dataIndex: "booking_date",
      key: "booking_date",
      width: 100,
      align: "center",
      render: (value: string) => {
        const date = value.split("-").reverse().join("-");
        return <div>{date}</div>;
      },
    },
    {
      title: t("table:table-time"),
      dataIndex: "booking_times",
      key: "booking_times",
      width: 120,
      align: "center",
      render: (value: string) => {
        const date = value.split(" ").join(",");
        return <div>{date}</div>;
      },
    },
    {
      title: t("table:table-item-status"),
      dataIndex: "booking_status",
      key: "booking_status",
      align: "center",
      className: "!text-sm",
      width: 100,
      render: (value: any) => (
        <div
          className={cn({
            "text-red-500": value === SlotStatus.Cancelled,
            "text-green-500": value === SlotStatus.Done,
            "text-blue-500": value === SlotStatus.Available,
            "text-orange-400": value === SlotStatus.Booked,
            "text-rose-400": value === SlotStatus.Closed,
          })}
        >
          {value}
        </div>
      ),
    },
    {
      title: t("table:table-item-customer"),
      dataIndex: "customer",
      key: "customer",
      align: "left",
      className: "!text-sm",
      width: 80,
    },
    {
      title: t("table:table-item-agent"),
      dataIndex: "agent",
      key: "agent",
      align: "left",
      className: "!text-sm",
      width: 80,
    },
    {
      title: t("table:table-item-action"),
      className: "cursor-pointer",
      dataIndex: "action",
      key: "action",
      align: "center",
      width: 100,
      render: function Render(value: number, record: ServiceBookingSlot) {
        return (
          <div
            style={{
              display: "flex flex-col",
              alignItems: "center",
              justifyContent: "center",
              gap: "5px",
            }}
          >
            <div className="flex gap-2 justify-start">
              <Button
                onClick={() => {
                  openModal("SERVICE_BOOKING_SLOT_DETAILS", {
                    item: record,
                  });
                }}
                variant="custom"
                className={cn(
                  "!w-24 mb-3 rounded bg-cyan-500 px-2 py-1 h-6 text-center text-x text-light shadow-md transition duration-200 ease-in hover:bg-cyan-700 focus:bg-cyan-700 focus:outline-none"
                )}
              >
                {t("common:text-details")}
              </Button>
              <Button
                onClick={() => {
                  openModal("SERVICE_BOOKING_SLOT_CLOSE", {
                    item: record,
                    callback: () => {
                      onPagination(1);
                    },
                  });
                }}
                variant="custom"
                className={cn(
                  "!w-24 mb-3 rounded bg-red-500 px-2 py-1 h-6 text-center text-xs text-light shadow-md transition duration-200 ease-in hover:bg-red-700 focus:bg-red-700 focus:outline-none"
                )}
              >
                {t("common:text-close")}
              </Button>
              <Button
                onClick={() => onReleaseItem(record)}
                variant="custom"
                className={cn(
                  "!w-24 mb-3 rounded bg-teal-500 px-2 py-1 h-6 text-center text-xs text-light shadow-md transition duration-200 ease-in hover:bg-teal-700 focus:bg-teal-700 focus:outline-none"
                )}
              >
                {t("common:text-release")}
              </Button>
            </div>
            <div className="flex gap-2 justify-start">
              <Button
                onClick={() => {
                  openModal("SERVICE_BOOKING_SLOT_BOOK", {
                    item: record,
                    callback: () => {
                      onPagination(1);
                    },
                  });
                }}
                variant="custom"
                className={cn(
                  "!w-24 mb-3 rounded bg-orange-500 px-2 py-1 h-6 text-center text-xs text-light shadow-md transition duration-200 ease-in hover:bg-orange-700 focus:bg-orange-700 focus:outline-none"
                )}
              >
                {t("common:text-book")}
              </Button>
              <Button
                onClick={() => {
                  openModal("SERVICE_BOOKING_SLOT_CANCEL", {
                    item: record,
                    callback: () => {
                      onPagination(1);
                    },
                  });
                }}
                variant="custom"
                className={cn(
                  "!w-24 mb-3 rounded bg-amber-500 px-2 py-1 h-6 text-center text-xs text-light shadow-md transition duration-200 ease-in hover:bg-amber-700 focus:bg-amber-700 focus:outline-none"
                )}
              >
                {t("common:text-cancel")}
              </Button>
              <Button
                onClick={() => {
                  openModal("SERVICE_BOOKING_SLOT_ASSIGN", {
                    item: record,
                    callback: () => {
                      onPagination(1);
                    },
                  });
                }}
                variant="custom"
                className={cn(
                  "!w-24 mb-3 rounded bg-blue-500 px-2 py-1 h-6 text-center text-xs text-light shadow-md transition duration-200 ease-in hover:bg-blue-700 focus:bg-blue-700 focus:outline-none"
                )}
              >
                {t("common:text-assign")}
              </Button>
            </div>
            <div className="flex gap-2 justify-start">
              <Button
                onClick={() => {
                  openModal("SERVICE_BOOKING_SLOT_COMPLETE", {
                    item: record,
                    callback: () => {
                      onPagination(1);
                    },
                  });
                }}
                variant="custom"
                className={cn(
                  "!w-24 mb-3 rounded bg-green-500 px-2 py-1 h-6 text-center text-xs text-light shadow-md transition duration-200 ease-in hover:bg-green-700 focus:bg-green-700 focus:outline-none"
                )}
              >
                {t("common:text-completed")}
              </Button>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="mb-6 overflow-hidden rounded shadow overflow-x-auto">
        <Table
          /* @ts-ignore */
          columns={columns}
          emptyText={t("table:empty-table-data")}
          data={serviceBookingSlotsList}
          rowKey="service_booking_record_id"
          // scroll={{ x: 900 }}
        />
      </div>

      {!!paginatorInfo?.total && (
        <div className="flex items-center justify-end py-4">
          <Pagination
            total={paginatorInfo.total}
            current={paginatorInfo.currentPage}
            pageSize={paginatorInfo.pageSize}
            onChange={onPagination}
            showLessItems
          />
        </div>
      )}
    </>
  );
};

export default BookingManagementList;
