import { Rating } from "@mui/material";
import Button from "components/ui/button";
import Loader from "components/ui/loader/loader";
import moment from "moment";
import { useModalAction, useModalState } from "provider/modal.context";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  useBusinessReviewApproveReject,
  useBusinessReviewDetails,
} from "service/hook/business";
import { userStore } from "store";
import { MainResponse } from "types";
import {
  BusinessReviewDetails,
  BusinessReviewDetailsRequest,
  BusinessReviewDetailsResponse,
} from "types/businessReview";

const BusinessReviewDetailsDialog = () => {
  const { t } = useTranslation("common");
  const { closeModal } = useModalAction();
  const { data } = useModalState();

  const [businessReviewDetails, setBusinessReviewDetails] =
    useState<BusinessReviewDetails>();
  const location = useLocation();
  const { businessItem } = userStore((state) => state);

  const { mutate: mutateBusinessReviewDetails, isLoading } =
    useBusinessReviewDetails();

  const { mutate: mutateBusinessReviewApproveReject } =
    useBusinessReviewApproveReject();

  const getMessageDetails = () => {
    const input: BusinessReviewDetailsRequest = {
      marketplaceId: "GBR",
      businessdetail_id: `${businessItem?.business_id}`,
      seller_score_details_id: data?.item?.seller_score_details_id,
    };

    mutateBusinessReviewDetails(input, {
      onSuccess: (successData: BusinessReviewDetailsResponse) => {
        if (successData.final_result_code === "000") {
          setBusinessReviewDetails(successData.captured_data);
        }
      },
    });
  };

  useEffect(() => {
    getMessageDetails();
  }, []);

  const determineReview = (status: string) => {
    mutateBusinessReviewApproveReject(
      {
        admin_user: businessReviewDetails?.customer_id ?? "",
        review_is_approved: status,
        seller_score_details_id: `${businessReviewDetails?.ssd_id ?? ""}`,
      },
      {
        onSuccess: (successData: MainResponse) => {
          if (successData.final_result_code === "000") {
            toast.success(successData.general_result);
            closeModal();
            data.callback && data.callback();
          } else {
            toast.error(successData.general_result);
          }
        },
      }
    );
  };

  return (
    <>
      <div
        className="m-auto rounded-md bg-light p-4 divide-y divide-gray-400"
        style={{ width: 700 }}
      >
        <div className="flex justify-between items-center">
          <h3 className="font-bold text-base">
            {t("common:text-business-details")}
          </h3>
        </div>
        {isLoading ? (
          <Loader className="!h-full flex-1" text={t("common:text-loading")} />
        ) : (
          <>
            <div className="flex flex-col">
              <div className="flex flex-col p-5 md:p-3 lg:px-4 lg:py-5">
                <p className="mb-2 flex w-full items-center text-sm text-heading last:mb-0 gap-4">
                  <span className="shrink-0 overflow-hidden font-bold">
                    {t("text-user")} :
                  </span>
                  <span className="ltr:ml-1 rtl:mr-1">
                    {businessReviewDetails?.customer_id}
                  </span>
                </p>
                <div className="flex gap-4 mb-2">
                  <span className="shrink-0 overflow-hidden font-bold text-sm">
                    {t("text-rate")} :
                  </span>
                  <Rating
                    name="review-rate"
                    value={businessReviewDetails?.seller_score}
                    readOnly
                    precision={0.25}
                  />
                </div>
                <p className="mb-2 flex w-full items-center text-sm text-heading last:mb-0 gap-4">
                  <span className="shrink-0 overflow-hidden font-bold">
                    {t("text-title")} :
                  </span>
                  <span className="ltr:ml-1 rtl:mr-1">
                    {businessReviewDetails?.review_title}
                  </span>
                </p>
                <p className="mb-2 flex w-full items-center text-sm text-heading last:mb-0 gap-4">
                  <span className="shrink-0 overflow-hidden font-bold">
                    {t("text-business-name")} :
                  </span>
                  <span className="ltr:ml-1 rtl:mr-1">
                    {businessItem?.business_name}
                  </span>
                </p>
                <p className="mb-2 flex w-full items-center text-sm text-heading last:mb-0 gap-4">
                  <span className="shrink-0 overflow-hidden font-bold">
                    {t("text-admin-user")} :
                  </span>
                  <span className="ltr:ml-1 rtl:mr-1">
                    {businessReviewDetails?.admin_user_who_approved}
                  </span>
                </p>
                <p className="mb-2 flex w-full items-center text-sm text-heading last:mb-0 gap-4">
                  <span className="shrink-0 overflow-hidden font-bold">
                    {t("text-date-controlled")} :
                  </span>
                  <span className="ltr:ml-1 rtl:mr-1">
                    {moment(businessReviewDetails?.datetime_of_approved).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )}
                  </span>
                </p>
                <p className="mb-2 flex w-full items-center text-sm text-heading last:mb-0 gap-4">
                  <span className="shrink-0 overflow-hidden font-bold">
                    {t("text-date")} :
                  </span>
                  <span className="ltr:ml-1 rtl:mr-1">
                    {moment(businessReviewDetails?.created_at).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )}
                  </span>
                </p>
                <p className="mb-2 flex w-full items-center text-sm text-heading last:mb-0 gap-4">
                  <span className="shrink-0 overflow-hidden font-bold">
                    {t("text-review")} :
                  </span>
                  <span className="ltr:ml-1 rtl:mr-1">
                    {businessReviewDetails?.review_content}
                  </span>
                </p>
              </div>
              <div className="mt-3 flex gap-4 justify-center w-full px-4">
                <Button
                  className="h-12 w-1/3 bg-orange-300 hover:border-orange-400 hover:bg-orange-400"
                  onClick={closeModal}
                >
                  {t("common:text-cancel")}
                </Button>
                {businessReviewDetails?.review_is_approved === null && (
                  <>
                    <Button
                      className="h-12 w-1/3"
                      onClick={() => determineReview("True")}
                    >
                      {t("common:text-approve")}
                    </Button>
                    <Button
                      className="h-10 w-1/3  bg-red-400 hover:border-red-500 hover:bg-red-500"
                      loading={isLoading}
                      disabled={isLoading}
                      onClick={() => determineReview("False")}
                    >
                      {t("common:text-reject")}
                    </Button>
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default BusinessReviewDetailsDialog;
