import { Routes, Route } from "react-router-dom";
import { Routes as RoutesPath } from "../config/routes";
import AuthorizedRoute from "../components/AuthorizedRoute";
import ManageAvailabilityView from "views/service-booking/manage-availability/ManageAvailability";
import BookingManagementView from "views/service-booking/booking-managment/BookingManagement";

const ServiceBookingRoutes = () => {
  return (
    <Routes>
      <Route
        path={RoutesPath.ManageAvailability}
        element={
          <AuthorizedRoute userRole={""} route={null}>
            <ManageAvailabilityView />
          </AuthorizedRoute>
        }
      />
      <Route
        path={RoutesPath.BookingManagement}
        element={
          <AuthorizedRoute userRole={""} route={null}>
            <BookingManagementView />
          </AuthorizedRoute>
        }
      />
    </Routes>
  );
};

export default ServiceBookingRoutes;
