import { useTranslation } from "react-i18next";

import { useState } from "react";
import { PaginatorInfoProps, SortOrder } from "types";
import { useModalAction } from "provider/modal.context";
import moment from "moment";
import { ServiceBookingCondition } from "types/serviceBooking";
import Table from "rc-table";
import Pagination from "components/ui/pagination";
import { Eye } from "components/icons/eye-icon";
import { TrashIcon } from "components/icons/trash";
import { CalendarIcon } from "components/icons/calendar";

export type IProps = {
  serviceBookingsList: ServiceBookingCondition[] | undefined;
  paginatorInfo: PaginatorInfoProps | null;
  onPagination: (current: number) => void;
  onDeleteItem: (item: ServiceBookingCondition) => void;
};

type SortingObjType = {
  sort: SortOrder;
  column: string | null;
};

const ManageAvailabilityList = ({
  serviceBookingsList,
  paginatorInfo,
  onPagination,
  onDeleteItem,
}: IProps) => {
  const { t } = useTranslation();
  const { openModal } = useModalAction();

  const alignLeft = "left";
  const alignRight = "right";

  const [sortingObj, setSortingObj] = useState<SortingObjType>({
    sort: SortOrder.Desc,
    column: null,
  });

  const columns = [
    {
      title: t("table:table-item-condition-id"),
      dataIndex: "service_booking_condition_id",
      key: "service_booking_condition_id",
      width: 90,
      align: "center",
    },
    {
      title: t("table:table-item-start-date"),
      dataIndex: "start_date",
      key: "start_date",
      width: 120,
      align: "center",
      render: (value: string) => {
        const date = value.split("-").reverse().join("-");
        return <div>{date}</div>;
      },
    },
    {
      title: t("table:table-item-end-date"),
      dataIndex: "end_date",
      key: "end_date",
      width: 120,
      align: "center",
      render: (value: string) => {
        const date = value.split("-").reverse().join("-");
        return <div>{date}</div>;
      },
    },
    {
      title: t("table:table-item-action"),
      className: "cursor-pointer",
      dataIndex: "action",
      key: "action",
      align: "center",
      width: 50,
      render: function Render(value: number, record: ServiceBookingCondition) {
        return (
          <div
            className="space-x-4"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <button
              onClick={() => {
                openModal("SERVICE_BOOKING_CONDITION_DETAILS", {
                  item: record,
                });
              }}
              className="text-body transition duration-200 hover:text-heading focus:outline-none"
              title={t("common:text-details")}
            >
              <Eye width={16} />
            </button>
            <button
              onClick={() => {
                openModal("SERVICE_BOOKING_CONDITION_SLOTS", {
                  item: record,
                });
              }}
              className="text-body transition duration-200 hover:text-heading focus:outline-none"
              title={t("common:text-slot")}
            >
              <CalendarIcon width={16} />
            </button>
            <button
              onClick={() => {
                onDeleteItem(record);
              }}
              className="text-red-500 transition duration-200 hover:text-red-600 focus:outline-none"
              title={t("common:text-delete")}
            >
              <TrashIcon width={16} />
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="mb-6 overflow-hidden rounded shadow overflow-x-auto">
        <Table
          /* @ts-ignore */
          columns={columns}
          emptyText={t("table:empty-table-data")}
          data={serviceBookingsList}
          rowKey="id"
          // scroll={{ x: 900 }}
        />
      </div>

      {!!paginatorInfo?.total && (
        <div className="flex items-center justify-end py-4">
          <Pagination
            total={paginatorInfo.total}
            current={paginatorInfo.currentPage}
            pageSize={paginatorInfo.pageSize}
            onChange={onPagination}
            showLessItems
          />
        </div>
      )}
    </>
  );
};

export default ManageAvailabilityList;
