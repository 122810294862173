import React from "react";
import { useTranslation } from "react-i18next";
// import SubscriptionForm from "../../../components/businessSetup/subscription-form";
import BusinessSubscription from "../../../components/businessSetup/BusinessSubscriptionPanel";

export default function BusinessSetupSubscriptionView({
  children,
  layout,
}: any) {
  const { t } = useTranslation();

  return (
    <>
      <BusinessSubscription />
    </>
  );
}
