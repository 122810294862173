import React from "react";
import BusinessStoryFileForm from "components/business-in-person-form/business-story-file/BusinessStoryFileForm";
import { useTranslation } from "react-i18next";
import ManageAvailabilityFormView from "components/service-booking/manage-availability/ManageAvailabilityForm";

export default function ManageAvailabilityView() {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex border-b border-dashed border-border-base py-5 sm:py-8">
        <h1 className="text-lg font-semibold text-heading">
          {`${t("common:sidebar-nav-item-service-booking")} / ${t(
            "common:sidebar-nav-sub-item-manage-availability"
          )}`}
        </h1>
      </div>
      <ManageAvailabilityFormView />
    </>
  );
}
