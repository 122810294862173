import React, { Suspense } from "react";
import { useModalAction, useModalState } from "../../provider/modal.context";
import Modal from "./modal";
import BranchLocationBranchDeleteView from "../businessSetup/branch-location-delete-view";
import BusinessReviewDetailsDialog from "components/crm/modals/BusinessReviewDetails";
// import LoginView from "../auth/login-form";

const Login = React.lazy(() => import("../auth/login-form"));
const TeamMemberDeleteUser = React.lazy(
  () => import("../businessSetup/team-member-delete-view")
);
const BranchLocationDeleteBranch = React.lazy(
  () => import("../businessSetup/branch-location-delete-view")
);
const BankAccountDeleteAccount = React.lazy(
  () => import("../businessSetup/bank-account-delete-view")
);
const BusinessDocumentDeleteDocument = React.lazy(
  () => import("../businessSetup/Business-document-delete-view")
);
const ProductManagementDeletePolicy = React.lazy(
  () => import("../productManagement/policy-delete-view")
);
const ProductManagementAddPolicy = React.lazy(
  () => import("../productManagement/policy-add-view")
);
const ProductManagementAddImage = React.lazy(
  () => import("../productManagement/add-image-view")
);
const ProductManagementDeleteImage = React.lazy(
  () => import("../productManagement/image-delete-view")
);
const ProductManagementAddVideo = React.lazy(
  () => import("../productManagement/video-add-view")
);
const ProductManagementDeleteVideo = React.lazy(
  () => import("../productManagement/video-delete-view")
);
const ProductManagementInventorySelectImage = React.lazy(
  () => import("../productManagement/inventory-select-image-view")
);
const ProductManagementInventorySelectVideo = React.lazy(
  () => import("../productManagement/inventory-select-video-view")
);

const ProductManagementInventorySelectAspect = React.lazy(
  () => import("../productManagement/inventory-select-aspect-view")
);

const ProductManagementInventoryImageOrVideoPreview = React.lazy(
  () =>
    import("../productManagement/inventory-select-image-or-video-preview-view")
);

const ProductManagementInventoryDeleteItem = React.lazy(
  () => import("../productManagement/inventory-delete-view")
);

const ProductManagementOfferSelectSKU = React.lazy(
  () => import("../productManagement/offer-select-sku-view")
);

const ProductManagementOfferSelectPolicy = React.lazy(
  () => import("../productManagement/offer-select-policy-view")
);

const ListingUnListConfirmView = React.lazy(
  () => import("../productListing/listing-unlist-confirm-view")
);

const ProductManagementInventorySelectDocument = React.lazy(
  () => import("../businessSetup/inventory-select-document-view")
);

const BusinessInPersonServicesDetailsModal = React.lazy(
  () => import("../InPersonServices/InPersonServicesDetailsModal")
);

const BusinessStoryFileImageDeleteModel = React.lazy(
  () =>
    import(
      "components/business-in-person-form/business-story-file/BusinessStoryFileDelete"
    )
);

const BusinessStoryAddFileModal = React.lazy(
  () =>
    import(
      "components/business-in-person-form/business-story-file/BusinessStoryAddFile"
    )
);

const ReplyMessage = React.lazy(
  () => import("components/crm/modals/ReplyMessage")
);
const ProfileManagementTeamDelete = React.lazy(
  () => import("../businessSetup/ProfileManagementTeamDelete")
);

const ServiceBookingConditionDetailsDialog = React.lazy(
  () =>
    import(
      "../service-booking/manage-availability/modals/ServiceBookingConditionDetails"
    )
);

const ServiceBookingConditionSlotsListDialog = React.lazy(
  () =>
    import(
      "../service-booking/manage-availability/modals/ServiceBookingConditionSlotsList"
    )
);

const ServiceBookingConditionDeleteDialog = React.lazy(
  () =>
    import(
      "../service-booking/manage-availability/modals/ServiceBookingConditionDelete"
    )
);

const ServiceBookingConditionCreateDialog = React.lazy(
  () =>
    import(
      "../service-booking/manage-availability/modals/ServiceBookingConditionCreate"
    )
);
const ServiceBookingConditionPreviewSlotsDialog = React.lazy(
  () =>
    import(
      "../service-booking/manage-availability/modals/ServiceBookingConditionPreviewSlots"
    )
);
const ServiceBookingSlotDetailsDialog = React.lazy(
  () =>
    import(
      "../service-booking/booking-management/modals/ServiceBookingSlotDetails"
    )
);
const ServiceBookingSlotCloseDialog = React.lazy(
  () =>
    import(
      "../service-booking/booking-management/modals/ServiceBookingSlotClose"
    )
);
const ServiceBookingSlotCompleteDialog = React.lazy(
  () =>
    import(
      "../service-booking/booking-management/modals/ServiceBookingSlotComplete"
    )
);
const ServiceBookingSlotCancelDialog = React.lazy(
  () =>
    import(
      "../service-booking/booking-management/modals/ServiceBookingSlotCancel"
    )
);
const ServiceBookingSlotAssignDialog = React.lazy(
  () =>
    import(
      "../service-booking/booking-management/modals/ServiceBookingSlotAssign"
    )
);
const ServiceBookingSlotBookDialog = React.lazy(
  () =>
    import(
      "../service-booking/booking-management/modals/ServiceBookingSlotBook"
    )
);
const ManagedModal = () => {
  const { isOpen1, isOpen2, view, view2 } = useModalState();
  const { closeModal, closeModal2 } = useModalAction();

  return (
    <>
      <Modal open={isOpen1} onClose={closeModal} isOuterClickClose={isOpen2}>
        {view === "LOGIN_VIEW" && <Login />}
        {view === "TeamMember_Delete_User" && <TeamMemberDeleteUser />}
        {view === "BranchLocation_Delete_Branch" && (
          <BranchLocationDeleteBranch />
        )}
        {view === "BankAccount_Delete_account" && <BankAccountDeleteAccount />}
        {view === "BusinessDocument_Delete_Document" && (
          <BusinessDocumentDeleteDocument />
        )}
        {view === "ProductManagement_Delete_Policy" && (
          <ProductManagementDeletePolicy />
        )}
        {view === "ProductManagement_Add_Policy" && (
          <ProductManagementAddPolicy />
        )}
        {view === "ProductManagement_Add_Image" && (
          <ProductManagementAddImage />
        )}
        {view === "ProductManagement_Delete_Image" && (
          <ProductManagementDeleteImage />
        )}
        {view === "ProductManagement_Add_Video" && (
          <ProductManagementAddVideo />
        )}
        {view === "ProductManagement_Delete_Video" && (
          <ProductManagementDeleteVideo />
        )}
        {view === "ProductManagement_Inventory_Add_Image_From_ImageList" && (
          <ProductManagementInventorySelectImage />
        )}
        {view === "ProductManagement_Inventory_Add_Video_From_VideoList" && (
          <ProductManagementInventorySelectVideo />
        )}
        {view === "ProductManagement_Inventory_Add_Aspect_From_AspectList" && (
          <ProductManagementInventorySelectAspect />
        )}
        {view === "ProductManagement_Image_Or_Video_Preview" && (
          <ProductManagementInventoryImageOrVideoPreview />
        )}
        {view === "ProductManagement_Inventory_Delete" && (
          <ProductManagementInventoryDeleteItem />
        )}
        {view === "ProductManagement_Offer_Add_SKU_From_SKUList" && (
          <ProductManagementOfferSelectSKU />
        )}
        {view === "ProductManagement_Offer_Add_Policy_From_PolicyList" && (
          <ProductManagementOfferSelectPolicy />
        )}
        {view === "Listing_Un_List_Confirm" && <ListingUnListConfirmView />}
        {view ===
          "ProductManagement_Inventory_Add_Document_From_DocumentList" && (
          <ProductManagementInventorySelectDocument />
        )}
        {view === "InPersonServicesDetailsModal" && (
          <BusinessInPersonServicesDetailsModal />
        )}
        {view === "Business_Story_File_Delete" && (
          <BusinessStoryFileImageDeleteModel />
        )}
        {view === "Business_Story_Add_File" && <BusinessStoryAddFileModal />}
        {view === "REPLY_MESSAGE" && <ReplyMessage />}
        {view === "BUSINESS_PROFILE_MANAGEMENT_DELETE" && (
          <ProfileManagementTeamDelete />
        )}
        {view === "BUSINESS_REVIEW_DETAILS" && <BusinessReviewDetailsDialog />}
        {view === "SERVICE_BOOKING_CONDITION_DETAILS" && (
          <ServiceBookingConditionDetailsDialog />
        )}
        {view === "SERVICE_BOOKING_CONDITION_SLOTS" && (
          <ServiceBookingConditionSlotsListDialog />
        )}
        {view === "SERVICE_BOOKING_CONDITION_DELETE" && (
          <ServiceBookingConditionDeleteDialog />
        )}
        {view === "SERVICE_BOOKING_CONDITION_CREATE" && (
          <ServiceBookingConditionCreateDialog />
        )}
        {view === "SERVICE_BOOKING_SLOT_DETAILS" && (
          <ServiceBookingSlotDetailsDialog />
        )}
        {view === "SERVICE_BOOKING_SLOT_CLOSE" && (
          <ServiceBookingSlotCloseDialog />
        )}
        {view === "SERVICE_BOOKING_SLOT_COMPLETE" && (
          <ServiceBookingSlotCompleteDialog />
        )}
        {view === "SERVICE_BOOKING_SLOT_CANCEL" && (
          <ServiceBookingSlotCancelDialog />
        )}
        {view === "SERVICE_BOOKING_SLOT_ASSIGN" && (
          <ServiceBookingSlotAssignDialog />
        )}
        {view === "SERVICE_BOOKING_SLOT_BOOK" && (
          <ServiceBookingSlotBookDialog />
        )}
      </Modal>
      <Modal open={isOpen2} onClose={closeModal2}>
        {view2 === "ProductManagement_Image_Or_Video_Preview" && (
          <ProductManagementInventoryImageOrVideoPreview isSecond={true} />
        )}
        {view2 === "ProductManagement_Add_Policy" && (
          <ProductManagementAddPolicy isSecond={true} />
        )}
        {view2 === "SERVICE_BOOKING_CONDITION_PREVIEW_SLOTS" && (
          <ServiceBookingConditionPreviewSlotsDialog />
        )}
      </Modal>
    </>
  );
};

export default ManagedModal;
