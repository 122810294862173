import cn from "classnames";
import { CloseIcon } from "components/icons/close-icon";
import Button from "components/ui/button";
import { Routes } from "config/routes";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { authStore } from "../../store";
import Card from "components/ui/cards/card";
import Radio from "components/ui/radio/radio";
import { CheckMark } from "components/icons/checkmark";

export const featuresItems = [
  "Logo Section",
  "Showcase History/Experience",
  "Showcase Branch location",
  "Showcase Certifications & Licenses",
  "About Section",
  "Meet the Team",
  "Showcase partnerships",
  "Unique Web URL",
  "Web Templates",
  "Explore360",
  "Accountant Service",
  "Virtual Credit Support",
  "Direct Customer Contact Options",
  "Showcase In-Person Services",
  "Integrated Payment Processing",
  "Online Appointment Booking",
  "Showcase Your Business Story",
  "Advertise Your Services",
  "Customer Reviews",
  "Basic Analytic Reports",
  "Blue Badge (verified businesses)",
  "Leverage AI Copilot",
  "Busniess Intelligence Reports",
  "Parcel Delivery Management",
  "Products Inventory",
];

export const tiresFeaturesItems = [
  {
    title: "Essential",
    tierCode: "1",
    services: [
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ],
  },
  {
    title: "In-Person Eco",
    tierCode: "2",
    type: "1",
    services: [
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      false,
      false,
      false,
      false,
      false,
    ],
  },
  {
    title: "In-Person Pro",
    tierCode: "3",
    type: "1",
    services: [
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      false,
      false,
    ],
  },

  {
    title: "Online-Sale Eco",
    type: "2",
    tierCode: "4",
    services: [
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      false,
      true,
      false,
      true,
      true,
      true,
      true,
      false,
      false,
      false,
      true,
      { value: true, title: "(up to 20)" },
    ],
  },
  {
    title: "Online-Sale Standard",
    type: "2",
    tierCode: "5",
    services: [
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      false,
      true,
      false,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      { value: true, title: "(up to 200)" },
    ],
  },
  {
    title: "Online-Sale Pro",
    type: "2",
    tierCode: "6",
    services: [
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      false,
      true,
      false,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      { value: true, title: "(up to 1000)" },
    ],
  },
  {
    title: "Hybrid Standard",
    type: "3",
    tierCode: "7",
    services: [
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      { value: true, title: "(up to 200)" },
    ],
  },
  {
    title: "Hybrid Pro",
    type: "3",
    tierCode: "8",
    services: [
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      { value: true, title: "(up to 1000)" },
    ],
  },
];

export default function SubscriptionPanel() {
  const location = useLocation();
  const [radioValue, setRadioValue] = useState<string>("0");
  const navigate = useNavigate();

  const handleChoosePlan = (tiresItems: any) => {
    const token = authStore.getState().accessToken;
    if (token && token !== "") {
      navigate(
        `/admin${Routes.BusinessSetup}/${Routes.BusinessSetupSubscription}`
      );
    } else {
      navigate("/login");
    }
  };

  return (
    <>
      <div className="my-2 flex flex-wrap border-b border-dashed border-border-base">
        <div className="w-full flex items-center justify-center">
          <div className="w-3/3 items-center text-left  border-0 border-sky-400 divide-y rounded-lg px-8">
            <div className="flex flex-col gap-2 py-2">
              <div className="font-bold">Offers</div>
              <div>
                1.Tier upgrades: Users can upgrade to a higher tier at any time.
              </div>
              <div>
                2.Discounts: Discounts may be available for annual subscriptions
                or bulk purchases.
              </div>
              <div>
                3.Customized pricing: Custom pricing may be considered for
                businesses with unique requirements.
              </div>
              <div>
                4.Payment options: Credit card, debit card, and PayPal payments
                are accepted.
              </div>
              <div>
                5.Cancellation policy: Subscriptions can be cancelled at any
                time, but no refunds will be issued for partial months of
                service.
              </div>
              <div>
                6.BAME group and Women entrepreneur will get a 35% discount.
              </div>
            </div>
          </div>
        </div>
        <Card className="!p-2 w-full sm:w-8/12 md:w-full bg-sky-50">
          <div
            className={"w-full flex items-center justify-center space-x-8 py-3"}
          >
            <Radio
              label={"Subscriptions All"}
              name="subscriptionType"
              checked={radioValue === "0"}
              id="subType1"
              value={"0"}
              onChange={(event) => setRadioValue(event.target.value)}
            />
            <Radio
              label={"Subscriptions In-Person"}
              name="subscriptionType"
              id="subType2"
              value={"1"}
              checked={radioValue === "1"}
              onChange={(event) => setRadioValue(event.target.value)}
            />
            <Radio
              label={"Subscriptions Online-Sale"}
              name="subscriptionType"
              id="subType3"
              value={"2"}
              checked={radioValue === "2"}
              onChange={(event) => setRadioValue(event.target.value)}
            />
            <Radio
              label={"Subscriptions Hybrid"}
              name="subscriptionType"
              id="subType4"
              value={"3"}
              checked={radioValue === "3"}
              onChange={(event) => setRadioValue(event.target.value)}
            />
          </div>

          <div className="w-full flex gap-2">
            <div className="flex flex-col w-[35%]">
              <div
                className={cn(
                  "flex text-slate-900 text-sm font-bold gap-2 bg-white"
                )}
              >
                <div className="px-4 border-r-4 border-slate-100 w-12"></div>
                <div className={`text-center w-full`}>Features</div>
              </div>
              {featuresItems.map((mapItem, index) => {
                return (
                  <div
                    className={cn(
                      "flex text-slate-900 text-sm gap-2",
                      index % 2 === 0 ? " bg-blue-100" : "bg-slate-100"
                    )}
                  >
                    <div className="px-3 border-r-4 border-slate-100 w-12 text-center">
                      {index + 1}
                    </div>
                    <div className={`text-left`}>{mapItem}</div>
                  </div>
                );
              })}
              <div
                className={cn(
                  "flex text-slate-900 text-sm font-bold gap-2 bg-white"
                )}
              >
                <div className="px-4 border-r-4 border-slate-100 w-12"></div>
                <div className="py-2 flex justify-center items-center bg-white h-9"></div>
              </div>
            </div>

            {tiresFeaturesItems.map((mapItem, index) => {
              return (
                (!mapItem.type ||
                  radioValue === "0" ||
                  mapItem.type === radioValue) && (
                  <div className="flex gap-2 w-[8%]" key={`feat-${index}`}>
                    <div className="flex flex-col w-full">
                      <div
                        className={`text-center w-full bg-white font-bold text-sm`}
                      >
                        {mapItem.title}
                      </div>
                      {mapItem.services.map((serviceItem, indexServiceItem) => {
                        return (
                          <div
                            className={`text-lg text-center text-slate-900 h-5 ${
                              indexServiceItem % 2 === 0
                                ? " bg-blue-100"
                                : "bg-slate-100"
                            }`}
                          >
                            {typeof serviceItem === "boolean" ? (
                              <div
                                style={{
                                  // textAlign: "center",
                                  justifyContent: "center",
                                }}
                                className={cn(
                                  "flex w-full text-center ",
                                  serviceItem
                                    ? "text-green-500"
                                    : "text-red-500"
                                )}
                              >
                                {serviceItem ? (
                                  <CheckMark width={14} />
                                ) : (
                                  <CloseIcon width={14} />
                                )}
                              </div>
                            ) : (
                              <>
                                <div className="flex gap-3 justify-center items-center text-sm">
                                  {serviceItem.value ? (
                                    <>
                                      <div className="text-green-500">
                                        <CheckMark width={14} />
                                      </div>
                                      <div>{serviceItem?.title}</div>
                                    </>
                                  ) : (
                                    <>
                                      <div className="text-red-500">
                                        <CloseIcon width={14} />
                                      </div>
                                      <div>{serviceItem?.title}</div>
                                    </>
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                        );
                      })}
                      <div className="py-1 flex justify-center items-center bg-white">
                        <Button
                          type="button"
                          className={`!h-7 font-bold text-sm`}
                          onClick={() => handleChoosePlan(mapItem)}
                        >
                          Choose Plan
                        </Button>
                      </div>
                    </div>
                  </div>
                )
              );
            })}
          </div>
          <Button
            type="button"
            className={`!h-9 font-bold text-sm !w-max mt-4 bg-cyan-500 !border-cyan-500 cursor-pointer hover:bg-cyan-600 `}
            onClick={() =>
              window.open(
                `${process.env.PUBLIC_URL}/files/subscription_guidance.html`,
                "_blank"
              )
            }
          >
            Subscription guidance
          </Button>
        </Card>
      </div>

      <div className="mb-4 text-end"></div>
    </>
  );
}
