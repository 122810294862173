import { Card } from "@mui/material";
import Button from "components/ui/button";
import { DatePicker } from "components/ui/date-picker";
import Description from "components/ui/description";
import Label from "components/ui/label";
import Select from "components/ui/select/select";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useProductManagementMarketPlaceList } from "service/hook/business";
import {
  ProductManagementMarketPlaceListItem,
  ProductManagementMarketPlaceListResponse,
  ProductManagementMarketPlaceReq,
} from "types";
import ManageAvailabilityList from "./ManageAvailabilityList";
import { userStore } from "store";
import { useServiceBookingConditionsList } from "service/hook/serviceBooking";
import { PageSize } from "utils/constants";
import {
  ServiceBookingCondition,
  ServiceBookingsListResponse,
} from "types/serviceBooking";
import moment from "moment";
import { useModalAction } from "provider/modal.context";

export default function ManageAvailabilityFormView() {
  const { t } = useTranslation();
  const { businessItem } = userStore((state) => state);
  const { openModal } = useModalAction();

  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [marketPlaceList, setMarketPlaceList] = useState<
    ProductManagementMarketPlaceListItem[]
  >([]);
  const [selectedMarketPlace, setSelectedMarketPlace] = useState<
    ProductManagementMarketPlaceListItem | undefined
  >(undefined);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [serviceBookingConditions, setServiceBookingConditions] = useState<
    ServiceBookingCondition[]
  >([]);

  const { mutate: mutateMarketPlaceList, isLoading: loadingMarketPlaceList } =
    useProductManagementMarketPlaceList();

  const {
    mutate: mutateServiceBookingConditionsList,
    isLoading: loadingServiceBookingConditionsList,
  } = useServiceBookingConditionsList();

  const getMarketPlaceList = (id?: string) => {
    const input: ProductManagementMarketPlaceReq = {};

    if (id) {
      input.marketplace_id = id;
    }

    mutateMarketPlaceList(input, {
      onSuccess: (successData: ProductManagementMarketPlaceListResponse) => {
        if (successData.final_result_code === "000") {
          const newMarketPlaces = successData.captured_data?.filter(
            (item) => item.marketplace_id === "GBR"
          );
          setMarketPlaceList(newMarketPlaces ?? []);
        }
      },
    });
  };

  const getServiceBookingConditionsList = (pageNumber?: number) => {
    mutateServiceBookingConditionsList(
      {
        businessdetail_id: `${businessItem?.business_id ?? 0}`,
        start_date: startDate
          ? moment(startDate).format("DD-MM-YYYY")
          : undefined,
        end_date: endDate ? moment(endDate).format("DD-MM-YYYY") : undefined,
        marketplaceId: selectedMarketPlace?.marketplace_id ?? "GBR",
        page: pageNumber ?? page,
        size: PageSize,
      },
      {
        onSuccess: (successData: ServiceBookingsListResponse) => {
          if (successData.final_result_code === "000") {
            setServiceBookingConditions(successData.captured_data);
            setTotalCount(successData.total_count ?? 0);
          }
        },
      }
    );
  };

  const deleteItem = async (item: any) => {
    openModal("SERVICE_BOOKING_CONDITION_DELETE", {
      item: { ...item },
      callback: () => {
        getServiceBookingConditionsList(1);
      },
    });
  };

  useEffect(() => {
    getMarketPlaceList();
  }, []);

  useEffect(() => {
    if (businessItem) getServiceBookingConditionsList();
  }, [businessItem, page]);

  const handleChangePage = (pageNumber: number) => {
    setPage(pageNumber);
  };

  return (
    <div className="my-5 flex flex-wrap sm:my-8">
      <Description
        details={[
          t("form:form-description-service-booking-manage-availability1"),
          t("form:form-description-service-booking-manage-availability2"),
          t("form:form-description-service-booking-manage-availability3"),
        ]}
        className="w-full px-0 pb-5 sm:w-4/12 sm:py-8 sm:pe-4 md:w-1/3 md:pe-5 "
      />
      <Card className="w-full sm:w-8/12 md:w-2/3">
        <div className="p-4">
          <div className="flex gap-4 w-full">
            <div className="w-3/4 flex gap-4">
              <div className="w-1/2">
                <Label className="text-left">{"Start date"}</Label>
                <DatePicker
                  className="w-full"
                  selected={startDate}
                  onChange={(date: any) => {
                    setStartDate(date);
                  }}
                  showYearDropdown
                  showMonthDropdown
                  dateFormat="yyy-MM-dd"
                />
              </div>
              <div className="w-1/2">
                <Label className="text-left">{"End date"}</Label>
                <DatePicker
                  className="w-full"
                  selected={endDate}
                  onChange={(date: any) => {
                    setEndDate(date);
                  }}
                  showYearDropdown
                  showMonthDropdown
                  dateFormat="yyy-MM-dd"
                />
              </div>
            </div>
            <div className="w-1/4">
              <div className="mb-5 w-full">
                <Label className="text-left">{"Marketplace"}</Label>
                <Select
                  name="marketPlace"
                  value={selectedMarketPlace}
                  onChange={(value: any) => {
                    setSelectedMarketPlace(value);
                  }}
                  getOptionLabel={(option: any) => `${option.marketplace_name}`}
                  getOptionValue={(option: any) => option.marketplace_id}
                  options={marketPlaceList!}
                  isLoading={loadingMarketPlaceList}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className="mb-4 text-start px-4"
          style={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <div
            className="mb-4 text-start"
            style={{
              display: "flex",
              justifyContent: "start",
            }}
          >
            <Button
              onClick={() => {
                getServiceBookingConditionsList(1);
              }}
              loading={loadingServiceBookingConditionsList}
              disabled={loadingServiceBookingConditionsList}
            >
              {"Search"}
            </Button>
            <Button
              variant={"secondary"}
              onClick={() => {
                openModal("SERVICE_BOOKING_CONDITION_CREATE", {
                  item: null,
                  callback: () => {
                    page === 1
                      ? getServiceBookingConditionsList()
                      : handleChangePage(1);
                  },
                });
              }}
              className={"ml-4"}
              loading={false}
              disabled={loadingServiceBookingConditionsList}
            >
              {t("common:text-create")}
            </Button>
          </div>
        </div>

        <ManageAvailabilityList
          serviceBookingsList={serviceBookingConditions}
          paginatorInfo={{
            total: totalCount,
            currentPage: page,
            pageSize: PageSize,
          }}
          onPagination={handleChangePage}
          onDeleteItem={(item: ServiceBookingCondition) => {
            deleteItem(item);
          }}
        />
      </Card>
    </div>
  );
}
