import { useTranslation } from "react-i18next";

import Pagination from "components/ui/pagination";
import { useModalAction } from "provider/modal.context";
import Table from "rc-table";
import { useState } from "react";
import { PaginatorInfoProps, SortOrder } from "types";
import { FinancialTransaction } from "types/financialTransactions.models";
import Currency from "utils/constants/currencyObj.json";

export type IProps = {
  financialTransactionsList: FinancialTransaction[] | undefined;
  paginatorInfo: PaginatorInfoProps | null;
  onPagination: (current: number) => void;
};

type SortingObjType = {
  sort: SortOrder;
  column: string | null;
};

const TransactionsList = ({
  financialTransactionsList,
  paginatorInfo,
  onPagination,
}: IProps) => {
  const { t } = useTranslation();
  const { openModal } = useModalAction();

  const alignLeft = "left";
  const alignRight = "right";

  const [sortingObj, setSortingObj] = useState<SortingObjType>({
    sort: SortOrder.Desc,
    column: null,
  });

  const columns: any[] = [
    {
      title: t("table:table-id"),
      dataIndex: "transaction_id",
      key: "transaction_id",
      width: 80,
      align: "center",
    },
    {
      title: t("table:table-amount-paid"),
      dataIndex: "amount_paid",
      key: "amount_paid",
      width: 100,
      align: "center",
    },
    {
      title: t("table:table-amount-receive"),
      dataIndex: "amount_received",
      key: "amount_received",
      width: 100,
      align: "center",
    },
    {
      title: t("table:table-currency"),
      dataIndex: "currency_type",
      key: "currency_type",
      width: 80,
      align: "center",
      render: (value: string) => {
        return <div>{(Currency as any)[value]}</div>;
      },
    },
    {
      title: t("table:table-date"),
      dataIndex: "transaction_date",
      key: "transaction_date",
      width: 100,
      align: "center",
    },
    {
      title: t("table:table-time"),
      dataIndex: "_transaction_time",
      key: "_transaction_time",
      width: 100,
      align: "center",
    },
    {
      title: t("table:table-marketplace-id"),
      dataIndex: "marketplaceId",
      key: "marketplaceId",
      width: 80,
      align: "center",
    },
    {
      title: t("table:table-type"),
      dataIndex: "transaction_type",
      key: "transaction_type",
      width: 80,
      align: "center",
    },
  ];
  return (
    <>
      <div className="mb-6 overflow-hidden rounded shadow overflow-x-auto">
        <Table
          /* @ts-ignore */
          columns={columns}
          emptyText={t("table:empty-table-data")}
          data={financialTransactionsList}
          rowKey="id"
          // scroll={{ x: 900 }}
        />
      </div>

      {!!paginatorInfo?.total && (
        <div className="flex items-center justify-end py-4">
          <Pagination
            total={paginatorInfo.total}
            current={paginatorInfo.currentPage}
            pageSize={paginatorInfo.pageSize}
            onChange={onPagination}
            showLessItems
          />
        </div>
      )}
    </>
  );
};

export default TransactionsList;
